import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { ThemeProvider } from './themes/index'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import store from './ducks'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import LanguageProvider from './utils/LanguageProvider'
import { MassiveUploads } from './massiveUploads'

Sentry.init({
  dsn: 'https://2f7780cec6764efcae89ccb8aef7409a@o455434.ingest.sentry.io/6260252',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <LanguageProvider>
          <BrowserRouter>
            <App {...window.xprops} />
          </BrowserRouter>
        </LanguageProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
console.log(MassiveUploads)
