/* eslint-disable react/prop-types */
import React from 'react'
import { Button } from '@material-ui/core'

const MButton = props => {
  const { text, onClick, variant, disabled } = props
  return (
    <Button
      variant={variant || 'contained'}
      color="primary"
      type={props.type}
      onClick={onClick}
      style={{ ...props.style, marginTop: '10px' }}
      disabled={disabled ? true : false}
    >
      {text}
    </Button>
  )
}

const MaterialButton = MButton

export { MaterialButton }
