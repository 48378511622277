import { FormattedMessage } from 'react-intl'
import { defaultColumn } from '../../util/form-const'
import messages from './messages'
import {
  getAllCitys,
  getAllComuneByCitys,
  getAllCountries,
  getIdentificationTypesByTennant,
} from '../../services/ProviderServices'
import {
  generateAccountBankTypesByBuList,
  generateBanksNamesByBuList,
} from '../../services/professionalServices'
import { getCitiesByCountryId } from '../../services/servicesDefinitions'
import {
  generateProfessionalsExcelFile,
  generateProvidersExcelFile,
} from './generateExcel'
import { getCountries } from '../../services/ubigeo'
import { filtersTagEvents } from '../../util/consts'
import { getIdentificationTypes } from '../../services/identificationTypesService'

const Columns = [
  {
    ...defaultColumn,
    text: <FormattedMessage {...messages.creationDate} />,
    tag: 'creationDate',
    field: 'fechaCreacion',
    visible: false,
  },
  {
    ...defaultColumn,
    text: 'ID',
    tag: 'id',
    field: 'id',
    visible: false,
  },
  {
    ...defaultColumn,
    text: <FormattedMessage {...messages.templateName} />,
    tag: 'templateName',
    field: 'nombreEmpresa',
  },
]

const Actions = (
  row,
  setBulkLoadModalOpen,
  setTitleBulkLoad,
  setIsIconDisabled,
  setShowIconWaiting,
) => {
  const setters = status => {
    setIsIconDisabled(status)
    setShowIconWaiting(status)
  }

  const openDialog = option => {
    setBulkLoadModalOpen(true)
    setTitleBulkLoad(option)
    setShowIconWaiting(false)
  }

  return [
    {
      text: (
        <a href={'#'} style={{ textDecoration: 'none', color: 'inherit' }}>
          <FormattedMessage {...messages.downloadTemplate} />
        </a>
      ),
      close: true,
      visible: true,
      action: async data => {
        try {
          let res = null
          let link = null
          switch (data.id) {
            case filtersTagEvents.professionalTemplateId:
              res = await generateData(filtersTagEvents.professionalTemplateId)
              if (res.success) {
                await generateProfessionalsExcelFile(res)
                setters(false)
              }
              break
            case filtersTagEvents.providerTemplateId:
              res = await generateData(filtersTagEvents.providerTemplateId)
              if (res.success) {
                await generateProvidersExcelFile(res)
                setters(false)
              }
              break
            case filtersTagEvents.servicesTemplateId:
              openDialog('Servicios')
              break
            case filtersTagEvents.zonesTemplateId:
              openDialog('Zonas')
              break
            case filtersTagEvents.costConceptTemplateId:
              openDialog('concepto de costo')
              break
            default:
              link = document.createElement('a')
              link.href = row.url
              link.setAttribute('download', row.templateName)
              document.body.appendChild(link)
              link.click()
              link.parentNode.removeChild(link)
              setters(false)
              break
          }
        } catch (e) {
          console.log(e)
        }
      },
    },
  ]
}

const generateData = async id => {
  let resBu = JSON.parse(localStorage['businessUnits'] || '[]')
  let buList = resBu.map(e => {
    return { uuid: e.id, name: e.name }
  })
  let documentTypes
  let accountTypesBank
  let countries
  let discountTypes
  let boolean
  let banks
  let retentionTypes
  const objCountriesNames = {}

  try {
    countries = await generateCountries(id, objCountriesNames)
    documentTypes = await generateDocumentTypes(id, objCountriesNames)
    banks = await generateBanksNamesByBuList(buList)
    discountTypes = generateDiscountTypes()
    boolean = generateBoolean()
    retentionTypes = generateRetentionTypes()
    accountTypesBank = await generateAccountBankTypesByBuList(buList)
    retentionTypes = generateRetentionTypes()
    return {
      success: true,
      buList,
      documentTypes,
      accountTypesBank,
      countries,
      discountTypes,
      boolean,
      banks,
      retentionTypes,
    }
  } catch (e) {
    console.error(e)
    return { success: false }
  }
}

const generateDocumentTypes = async (id, objCountriesNames) => {
  if (id === filtersTagEvents.professionalTemplateId) {
    let res = await getIdentificationTypes()
    const identificationTypes = []
    if (res.status === 200) {
      res.data?.forEach(({ identificationTypes: documents, countryId }) => {
        documents.forEach(item => {
          identificationTypes.push({
            id: item.id,
            name: `${item.name} - ${objCountriesNames[countryId] || ''}`,
            country: countryId,
          })
        })
      })
    }
    return identificationTypes.sort((a, b) => a.name.localeCompare(b.name))
  } else {
    let res = await getIdentificationTypesByTennant()
    if (res.status === 200) {
      return res.data.identificationTypes?.map(i => {
        return { id: i.id, name: i.name }
      })
    }
  }
}

const generateCountries = async (idTemplate, objCountriesNames) => {
  if (idTemplate === filtersTagEvents.professionalTemplateId) {
    let resCountries = await getCountries()
    if (resCountries.status === 200) {
      return resCountries.data?.map(c => {
        objCountriesNames[c.id] = c.name
        return {
          id: c.id,
          name: c.name,
        }
      })
    } else {
      return []
    }
  } else {
    let res = await getAllCountries()
    if (res.status === 200) {
      let resCitys = await getAllCitys()
      if (resCitys.status === 200) {
        const citysIds = resCitys.data?.map(item => item.id)
        let resComunes = await getAllComuneByCitys(citysIds)
        return Promise.all(
          res.data.items?.map(async c => {
            return {
              id: c.id,
              name: c.name,
              banks: [],
              cities: await generateDataCitiesCountry(
                c,
                resCitys?.data,
                resComunes?.data,
              ),
            }
          }),
        )
      }
    }
  }
}

const generateDataCitiesCountry = async (country, citys, comunes) => {
  const citysContry = citys.filter(item => item.countryName === country.name)
  return Promise.all(
    citysContry?.map(async c => {
      return {
        id: c.id,
        name: c.name + ' - ' + country.name,
        countryId: country.id,
        communes: await generateDataCommunesCity(c, comunes),
      }
    }),
  )
}

const generateDataCommunesCity = async (city, comunes) => {
  const comuneList = comunes?.filter(item => item.cityId === city.id)
  return Promise.all(
    comuneList?.map(async c => {
      return {
        id: c.comuneId,
        name: c.comuneName + ' - ' + city.name,
        cityId: city.id,
        cityName: city.name,
      }
    }),
  )
}

const generateDiscountTypes = () => {
  return [{ name: 'Porcentaje' }, { name: 'valor' }]
}

const generateBoolean = () => {
  return [{ name: 'true' }, { name: 'false' }]
}

const generateRetentionTypes = () => [
  { id: 1, name: 'Factura' },
  { id: 2, name: 'Boleta' },
]

export { Columns, Actions }
