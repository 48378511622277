import Spanish from '../../translations/es.json'
import English from '../../translations/en.json'
import { IntlProvider } from 'react-intl'

const local = navigator.language
let language = local === 'en-US' ? English : Spanish
const LanguageProvider = props => {
  return (
    <IntlProvider locale={local} messages={language}>
      {props.children}
    </IntlProvider>
  )
}
export default LanguageProvider
