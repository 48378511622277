import axios from 'axios'
import { sessionKeys } from '../util/consts'

const apiName = '/LoginREST/rest'
const API_URL = process.env.REACT_APP_AIR_API_URL
export const checkSession = body => {
  return axios
    .post(`${API_URL}${apiName}/login/administrator/sso/check`, body, {
      headers: { app: 'APP_BCK' },
    })
    .then(res => res.data)
    .catch(err => {
      throw err
    })
}

export const getBusinessUnitByUserId = async () => {
  return axios
    .get(`${API_URL}${apiName}/login/business/unit/by/user`, {
      headers: {
        userId: parseInt(window.localStorage['userId']),
        token: window.localStorage['token'],
        app: 'APP_BCK',
      },
    })
    .then(res => res.data)
    .catch(err => {
      throw err
    })
}

export const getAllCities = () => {
  return axios
    .get(`${API_URL}/ServiceDefinitionREST/rest/servicedefinition/cities/all`, {
      headers: {
        userId: parseInt(window.localStorage['userId']),
        token: window.localStorage['token'],
        app: 'APP_BCK',
      },
    })
    .then(res => res.data)
    .catch(err => {
      throw err
    })
}

/**
 *
 * @param response
 * @description set session data in localStorage
 */
export const setSessionData = token => {
  return checkSession({ token: token })
    .then(sessionData => {
      if (!sessionData) {
        throw new Error('ERROR, USUARIO NO AUTORIZADO')
      }
      saveSessionData(sessionData)
      return getBusinessUnitByUserId()
    })
    .then(function (businessUnits) {
      if (businessUnits) {
        let tennatId = ''
        if (businessUnits[0]) {
          tennatId = businessUnits[0].tenant.id
        }
        window.localStorage.setItem(
          'businessUnits',
          JSON.stringify(businessUnits),
        )
        localStorage.setItem(sessionKeys.tenantUUID, tennatId)
      }
      return true
    })
    .catch(err => {
      console.error(err)
      window.localStorage.clear()
      throw new Error('ERROR, USUARIO NO AUTORIZADO')
    })
}

const saveSessionData = response => {
  /*session token*/
  window.localStorage.setItem('token', response.sessionToken)
  /* get custom theme */
  if (Object.prototype.hasOwnProperty.call(response, 'tenantTheme')) {
    const tenantTheme = response.tenantTheme
    if (!Object.keys(tenantTheme).includes('--active-sidebar-color')) {
      tenantTheme['--active-sidebar-color'] = 'transparent'
    }
    /* get custom favicon */
    if (Object.prototype.hasOwnProperty.call(tenantTheme, 'favicon-bo-ops')) {
      window.localStorage.setItem(
        'tenantFavicon',
        tenantTheme['favicon-bo-ops'] + '?v1',
      )
    } else {
      window.localStorage.setItem('tenantFavicon', response.tenantFavicon)
    }
    /* get custom title */
    if (
      Object.prototype.hasOwnProperty.call(tenantTheme, 'tenantTitle-bo-ops')
    ) {
      window.localStorage.setItem(
        'tenantTitle',
        tenantTheme['tenantTitle-bo-ops'],
      )
    } else {
      window.localStorage.setItem('tenantTitle', response.tenantTitle)
    }
    /* get custom logo */
    if (Object.prototype.hasOwnProperty.call(tenantTheme, 'logo-bo-ops')) {
      window.localStorage.setItem('tenantLogo', tenantTheme['logo-bo-ops'])
    } else {
      window.localStorage.setItem('tenantLogo', response.tenantLogo)
    }
  } else {
    window.localStorage.setItem('tenantFavicon', response.tenantFavicon)
    window.localStorage.setItem('tenantTitle', response.tenantTitle)
    window.localStorage.setItem('tenantLogo', response.tenantLogo)
  }
  window.localStorage.setItem('passwordNeedsReset', response.passwordNeedsReset)
  window.localStorage.setItem('SSOActive', response.SSOActive)
  window.localStorage.setItem('SSOLogoutURL', response.SSOLogoutURL)
  window.localStorage.setItem('firstName', response.firstName)
  window.localStorage.setItem('email', response.email)
  window.localStorage.setItem('lastName', response.lastName)
  window.localStorage.setItem('phone', response.phone)
  window.localStorage.setItem('passwordChangeDate', response.passwordChangeDate)
  window.localStorage.setItem('userId', response.userId)
  window.localStorage.setItem('username', response.username)
  window.localStorage.setItem('role', response.role.roleId)
  window.localStorage.setItem('initialView', response.role.initialView)
  window.localStorage.setItem('timeout', response.role.timeout)
  window.localStorage.setItem('roleName', response.role.name)
  window.localStorage.setItem('tenantName', response.tenantName)
  window.localStorage.setItem(
    'tenantEtiquetaBUPais',
    response.tenantEtiquetaBUPais,
  )
  window.localStorage.setItem(
    'tenantEtiquetaCiudadRegionPlural',
    response.tenantEtiquetaCiudadRegionPlural,
  )
  window.localStorage.setItem(
    'tenantEtiquetaCiudadRegionSingular',
    response.tenantEtiquetaCiudadRegionSingular,
  )
  window.localStorage.setItem(
    'tenantTheme',
    JSON.stringify(response.tenantTheme),
  )
  if (response.contractor != null) {
    window.localStorage.setItem(
      'contractorId',
      response.contractor.contractorId,
    )
  }
  var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = window.localStorage['tenantFavicon']
  document.getElementsByTagName('head')[0].appendChild(link)
  window.document.title = window.localStorage['tenantTitle']
}
