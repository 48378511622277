import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  root: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    '& .MuiIconButton-root': {
      alignSelf: 'start',
    },
  },
  snackClass: {
    transform: 'none !important',
  },
}))

const UseAlertComponent = () => {
  const classes = useStyles()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarTitle, setSnackbarTitle] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('error')
  const [snackbarDuration, setSnackbarDuration] = useState(4000)

  // recibe un string o un objeto tipo {id: 'app.general', defaultMessage: 'app.default'}
  // opcionalmente se le puede pasar la duración
  const openSnackbar = (message, severity, title, duration) => {
    setSnackbarSeverity(severity)
    setSnackbarTitle(title)
    setSnackbarMessage(message)
    setSnackbarDuration(duration || 4000)
    setSnackbarOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }
  let style = {
    textAlign: 'left',
    width: '380px',
    background: snackbarSeverity === 'error' ? '#F44336' : '#4CAF50',
  }

  const AlertComponent = ({ width }) => {
    style = width ? { ...style, width: width } : { ...style }
    return (
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={snackbarDuration || 4000}
          onClose={handleClose}
          className={classes.snackClass}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity={snackbarSeverity}
            style={style}
          >
            <AlertTitle>
              {typeof snackbarTitle === 'string' ? (
                snackbarTitle
              ) : (
                <FormattedMessage {...snackbarTitle} />
              )}
            </AlertTitle>
            {typeof snackbarMessage === 'string' ? (
              snackbarMessage
            ) : (
              <FormattedMessage {...snackbarMessage} />
            )}
          </Alert>
        </Snackbar>
      </div>
    )
  }
  return { AlertComponent, openSnackbar }
}

export default UseAlertComponent
