import { defineMessages } from 'react-intl'

export const scope = 'app.pages.MassiveUploadsMaintainer.TemplatesMaintainer'
const generalScope = 'app.general'

export default defineMessages({
  error: {
    id: `${generalScope}.error`,
    defaultMessage: 'Error',
  },
  errorGetData: {
    id: `${scope}.errorGetData`,
    defaultMessage: 'Error al obtener datos',
  },
  creationDate: {
    id: `${scope}.creationDate`,
    defaultMessage: 'Fecha de creación',
  },
  templateName: {
    id: `${scope}.templateName`,
    defaultMessage: 'Fecha de creación',
  },
  downloadTemplate: {
    id: `${scope}.downloadTemplate`,
    defaultMessage: 'Descargar',
  },
})
