import { Controller } from 'react-hook-form'
import Select from './select'

export const SelectWithController = ({
  data,
  control,
  name,
  placeholder,
  helperText,
  size,
  disabled,
  require,
}) => (
  <Controller
    name={name}
    key={name}
    control={control}
    render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
      return (
        <Select
          key={name}
          id={name}
          label={''}
          value={value}
          onChange={event => {
            onChange(event)
          }}
          error={!!error}
          required={true}
          data={data}
          placeholder={placeholder}
          helperText={helperText}
          fullWidth
          inputRef={ref}
          size={size}
          disabled={disabled}
        ></Select>
      )
    }}
    rules={{ required: require }}
  />
)
