import { useEffect, useState } from 'react'
import '../../styles/Branchoffices/Branchoffices.css'
import { Grid, Typography } from '@material-ui/core'
import { getBusinessUnit } from '../../services/servicesBusinessUnit'
import ZonesForm from './ZonesOrServicesForm/ZonesForm'
import ServicesForm from './ZonesOrServicesForm/ServicesFrom'
import { selectsName } from './ZonesOrServicesForm/constants'
import { FormattedMessage } from 'react-intl'
const ModalServicesCCBulkLoad = props => {
  //#region PARAMS
  //params general
  const {
    idSucursal,
    setIdSucursal,
    titleBulkLoad,
    businessUnitList,
    setCities,
    setBusSelected,
    selectedAll,
    setSelectedAll,
    setProviderSelected,
    setValue,
    control,
    watch,
    bulkLoadModalOpen,
  } = props
  const [buList, setBuList] = useState([])
  const [loadingBu, setLoadingBu] = useState(false)
  //params Zones Form
  const [regions, setRegions] = useState([])
  const [dataBUList, setDataBUList] = useState([])
  watch(selectsName.proListCtrl)
  //#endregion PARAMS
  setBusSelected(watch(selectsName.buListId))
  setProviderSelected(watch(selectsName.proListCtrl))

  useEffect(() => {
    const fetchBuListData = async () => {
      try {
        const { status, data: res } = await getBusinessUnit()
        if (status === 200) {
          setBuList(
            res
              .map(function (obj) {
                let value = obj.id
                let label = obj.name
                return { value: value, label: label }
              })
              .filter(bu => businessUnitList.includes(bu.value)),
          )
          setLoadingBu(false)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchBuListData()
  }, [])

  useEffect(() => {
    if (bulkLoadModalOpen) {
      setValue(selectsName.proListCtrl, null)
      setValue(selectsName.buListId, null)
      setValue(selectsName.categoryListId, null)
      setValue(selectsName.regionListCtrl, null)
      setSelectedAll(false)
    }
  }, [bulkLoadModalOpen])

  const getBusinessUnitsFronEvent = event => {
    // se utiliza un set para que filtre automaticamente los uuids que ya existen
    const listBUProviderSelected = new Set()
    event.forEach(({ listBusinessUnit }) =>
      listBusinessUnit?.forEach(businessUnit => {
        listBUProviderSelected.add(businessUnit)
      }),
    )
    // se toman de la lista del usuario solo los pertenecientes a los proveedores seleccionados
    return buList.filter(({ value }) => listBUProviderSelected.has(value))
  }

  // manejo se selector de proveedores para servicios
  const handleSelectedProv = e => {
    if (e.length > 0) {
      setDataBUList([])
      const tempIdSucursal = []
      e.forEach(p => p.sucursales?.forEach(s => tempIdSucursal.push(s)))
      setIdSucursal(tempIdSucursal)
      setDataBUList(getBusinessUnitsFronEvent(e))
    } else {
      setDataBUList([])
    }
  }

  const handleSelectedZone = e => {
    if (e.length === regions.length) setCities(generateCityList(regions))
    setCities(generateCityList(e))
  }
  const generateCityList = e => {
    let list = []
    e?.map(c => {
      list.push({
        countryId: c.country,
        countryName: c.countryName,
        cityId: c.value,
        cityName: c.label,
      })
    })
    return list
  }
  // manejo se selector de proveedores para zonas
  const handleSelectedProvider = e => {
    setValue(selectsName.buListId, getBusinessUnitsFronEvent(e))
  }
  const renderGenerateForm = () => {
    return (
      <>
        <Grid
          container
          spacing={2}
          style={{ paddingTop: '5px', paddingBottom: '25px' }}
        >
          <Grid item xs={12} sm={12} md={12} align="left">
            <Typography variant="body1" gutterBottom component="div">
              <FormattedMessage id="app.bulk.load.btn.generate.subtitle" />
            </Typography>
          </Grid>
          {titleBulkLoad === 'Zonas' && (
            <ZonesForm
              control={control}
              watch={watch}
              regions={regions}
              setRegions={setRegions}
              handleSelectedZone={handleSelectedZone}
              handleSelectedProvider={handleSelectedProvider}
              setSelectedAll={setSelectedAll}
              selectedAll={selectedAll}
            />
          )}
          {(titleBulkLoad === 'Servicios' ||
            titleBulkLoad == 'concepto de costo') && (
            <ServicesForm
              control={control}
              watch={watch}
              loadingBu={loadingBu}
              idSucursal={idSucursal}
              setValue={setValue}
              setSelectedAll={setSelectedAll}
              selectedAll={selectedAll}
              handleSelectedProv={handleSelectedProv}
              dataBUList={dataBUList}
              setDataBUList={setDataBUList}
            />
          )}
        </Grid>
      </>
    )
  }

  return (
    <div style={{ margin: '10px 16px' }}>
      <form action="/">{renderGenerateForm()}</form>
    </div>
  )
}

export default ModalServicesCCBulkLoad
