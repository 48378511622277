import { FormattedMessage } from 'react-intl'
import { professionalCharge } from '../../services/professionalServices'
import {
  costConceptsCharge,
  uploadCCrBulkLoad,
} from '../../services/costConceptsService'
import { defaultColumn } from '../../util/form-const'
import messages from './messages'
import ExcelJS from 'exceljs'
import {
  branchOfficesSrvCharge,
  branchOfficesNeighborhoodCharge,
} from '../../services/branchOfficesSrvService'
import { professionalsSheetName } from '../TemplatesMaintainer/generateExcel'

defaultColumn.sort = false

export const errorMessages = {
  ERROR_VALUE_REQUIRED: 'ERROR_VALUE_REQUIRED',
  ERROR_INVALID_VALUE_TYPE: 'ERROR_INVALID_VALUE_TYPE',
  HEADERS_NOT_FOUNT: 'HEADERS_NOT_FOUNT',
}

export const tipoDato = {
  NUMERICO: 'Numérico',
  TEXTO: 'Texto',
  BOOLEANO: 'Booleano',
  TELEFONO: 'Teléfono',
  EMAIL: 'Email',
}

export const dataValidationExcel = {
  DECIMAL: 'decimal',
  INTEGER: 'whole',
  LIST: 'list',
  TEXT: 'textLength',
}

export const Columns = [
  {
    text: <FormattedMessage {...messages.name} />,
  },
  {
    text: <FormattedMessage {...messages.typeOfLoad} />,
  },
  {
    text: <FormattedMessage {...messages.businessUnit} />,
  },
  {
    text: <FormattedMessage {...messages.size} />,
  },
  {
    text: '',
  },
]

/**
 * arreglo con los tipos de carga
 * por favor agregar los tipos de carga
 * que se vayan creando
 * Nota: la idea es migrar este arreglo a un endpoint
 */

const typeOfLoads = [
  {
    id: 1,
    label: 'Profesionales',
    value: 1,
    permission: 'MAKE_BULK_UPLOADS_PROFESSIONALS',
  },
  {
    id: 2,
    label: 'Conceptos de costos',
    value: 2,
    permission: 'MAKE_BULK_UPLOADS_COST_CONCEPTS',
  },
  {
    id: 3,
    label: 'Proveedores',
    value: 3,
    permission: 'MAKE_BULK_UPLOADS_PROVIDERS',
  },
  {
    id: 4,
    label: 'Servicios de Sucursales',
    value: 4,
    permission: 'MAKE_BULK_UPLOADS_PROFESSIONALS',
  },
  {
    id: 5,
    label: 'Zonas de Sucursales',
    value: 5,
    permission: 'MAKE_BULK_UPLOADS_PROFESSIONALS',
  },
  {
    id: 6,
    label: 'Asociación masiva de concepto de costo',
    value: 6,
    permission: 'MAKE_BULK_UPLOADS_PROFESSIONALS',
  },
]

export const columnsProviderExcel = {
  A: { h: 'Razón Social', k: 'razonSocial', c: 'A', i: 1 },
  B: { h: 'Nombre Empresa', k: 'nombreEmpresa', c: 'B', i: 2 },
  C: { h: 'Nombre Fantasia', k: 'nombreFantasia', c: 'C', i: 3 },
  D: {
    h: 'Tipo Documento',
    k: 'documentTypeList',
    c: 'D',
    i: 4,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'TipoDocumento!$A$2:$A$100000',
  },
  E: {
    h: 'Tipo Documento Id',
    k: 'tipoId',
    c: 'E',
    i: 5,
    f: '=VLOOKUP($D$2:$D$100000,TipoDocumento!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  F: { h: 'No. Documento', k: 'numeroId', c: 'F', i: 6 },
  G: { h: 'Giro', k: 'giro', c: 'G', i: 7 },
  H: { h: 'No. Trabajadores', k: 'numeroDeTrabajadores', c: 'H', i: 8 },
  I: {
    h: 'Unidad de Negocio',
    k: 'businessUnitList',
    c: 'I',
    i: 9,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'UnidadNegocio!$A$2:$A$100000',
  },
  J: {
    h: 'Unidad de Negocio Id',
    k: 'bussinessUnit',
    c: 'J',
    i: 10,
    f: '=VLOOKUP($I$2:$I$100000,UnidadNegocio!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  K: {
    h: 'Comuna',
    k: 'communeList',
    c: 'K',
    i: 11,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Comuna!$A$2:$A$100000',
  },
  L: {
    h: 'Comuna Id',
    k: 'comune',
    c: 'L',
    i: 12,
    f: '=VLOOKUP($K$2:$K$100000,Comuna!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  M: {
    h: 'Region',
    k: 'cityList',
    c: 'M',
    i: 13,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Region!$A$2:$A$100000',
  },
  N: {
    h: 'Region Id',
    k: 'province',
    c: 'N',
    i: 14,
    f: '=VLOOKUP($M$2:$M$100000,Region!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  O: {
    h: 'Pais',
    k: 'countryList',
    c: 'O',
    i: 15,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Pais!$A$2:$A$100000',
  },
  P: {
    h: 'Pais Id',
    k: 'pais',
    c: 'P',
    i: 16,
    f: '=VLOOKUP($O$2:$O$100000,Pais!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  Q: { h: 'Direccion', k: 'direccion', c: 'Q', i: 17 },
  R: { h: 'Longitud', k: 'longitude', c: 'R', i: 18 },
  S: { h: 'Latitud', k: 'latitude', c: 'S', i: 19 },
  T: {
    h: 'Nombre Representante Legal',
    k: 'nombreRepresentanteLegal',
    c: 'T',
    i: 20,
  },
  U: {
    h: 'Apellido Representante Legal',
    k: 'apellidoRepresentanteLegal',
    c: 'U',
    i: 21,
  },
  V: {
    h: 'Correo Representante Legal',
    k: 'correoRepresentanteLegal',
    c: 'V',
    i: 22,
  },
  W: {
    h: 'Telf/Cel Representante Legal',
    k: 'telefonoRepresentanteLegal',
    c: 'W',
    i: 23,
  },
  X: {
    h: 'Telf/Cel de Contacto Representante Legal',
    k: 'legalRepresentativeContactPhoneNumber',
    c: 'X',
    i: 24,
  },
  Y: { h: 'Nombre Administracion', k: 'administrationName', c: 'Y', i: 25 },
  Z: {
    h: 'Apellido Administracion',
    k: 'administrationLastName',
    c: 'Z',
    i: 26,
  },
  AA: { h: 'Correo Administracion', k: 'administrationEmail', c: 'AA', i: 27 },
  AB: {
    h: 'Telf/Cel Administracion',
    k: 'phoneAdministration',
    c: 'AB',
    i: 28,
  },
  AC: {
    h: 'Telf/Cel de Contacto Administracion',
    k: 'contactPhoneAdministration',
    c: 'AC',
    i: 29,
  },
  AD: {
    h: 'Usuario Administracion',
    k: 'administrationUserName',
    c: 'AD',
    i: 30,
  },
  AE: {
    h: 'Pronto Pago',
    k: 'paymentEarly',
    c: 'AE',
    i: 31,
    requerido: true,
    tipoDato: tipoDato.BOOLEANO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Boolean!$A$2:$A$100000',
  },
  AF: { h: 'Correo Pago', k: 'mailPayment', c: 'AF', i: 32 },
  AG: { h: 'Modalidad Pago', k: 'modalityPayment', c: 'AG', i: 33 },
  AH: { h: 'Nombre Titular', k: 'ownerName', c: 'AH', i: 34 },
  AI: {
    h: 'Banco',
    k: 'bankList',
    c: 'AI',
    i: 35,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Banco!$A$2:$A$100000',
  },
  AJ: {
    h: 'Banco Id',
    k: 'bank',
    c: 'AJ',
    i: 36,
    f: '=VLOOKUP($AI$2:$AI$100000,Banco!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  AK: {
    h: 'Tipo Cuenta',
    k: 'accountTypeBankList',
    c: 'AK',
    i: 37,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'TipoCuentaBancaria!$A$2:$A$100000',
  },
  AL: {
    h: 'Tipo Cuenta Id',
    k: 'tipoCuenta',
    c: 'AL',
    i: 38,
    f: '=VLOOKUP($AK$2:$AK$100000,TipoCuentaBancaria!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  AM: { h: 'Número de Cuenta', k: 'rut', c: 'AM', i: 39 },
  AN: {
    h: 'Tipo Documento Banco',
    k: 'bankDocumentTypeList',
    c: 'AN',
    i: 40,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'TipoDocumento!$A$2:$A$100000',
  },
  AO: {
    h: 'Tipo Documento Banco Id',
    k: 'bankDocumentType',
    c: 'AO',
    i: 41,
    f: '=VLOOKUP($AN$2:$AN$100000,TipoDocumento!$A$2:$B$100000,2,FALSE)',
    hidden: true,
  },
  AP: { h: 'No. Documento Banco', k: 'bankDocumentNumber', c: 'AP', i: 42 },
  AQ: { h: 'Direccion Sucursal', k: 'branchOfficeAddress', c: 'AQ', i: 43 },
  AR: { h: 'Nombre Contacto', k: 'contacName', c: 'AR', i: 44 },
  AS: { h: 'Primer Apellido', k: 'firstLastNameContact', c: 'AS', i: 45 },
  AT: { h: 'Correo', k: 'email', c: 'AT', i: 46 },
  AU: { h: 'Telefono', k: 'telefono', c: 'AU', i: 47 },
  AV: { h: 'Telefono Contacto', k: 'contactPhone', c: 'AV', i: 48 },
  AW: { h: 'Nombre Sucursal', k: 'bussinessName', c: 'AW', i: 49 },
  AX: {
    h: 'Tipo de retención',
    k: 'retentionTypeName',
    c: 'AX',
    i: 50,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Retencion!$A$2:$A$1000000',
    requerido: true,
  },
  AY: {
    h: 'ID de retención',
    k: 'retentionType',
    c: 'AY',
    i: 51,
    parentCol: 'AX',
    f: '=IFERROR(VLOOKUP($AX$2:$AX$10000,Retencion!$A$2:$B$10000,2,FALSE),"")',
    dvType: dataValidationExcel.INTEGER,
    hidden: true,
  },
  AZ: {
    h: 'Porcentaje',
    k: 'retentionPercentage',
    c: 'AZ',
    i: 52,
    dvType: dataValidationExcel.DECIMAL,
  },
  BA: { h: 'Lunes Inicio', k: 'mondayStart', c: 'BA', i: 53, width: 10 },
  BB: { h: 'Lunes Fin', k: 'mondayEnd', c: 'BB', i: 54, width: 10 },
  BC: { h: 'Martes Inicio', k: 'tuesdayStart', c: 'BC', i: 55, width: 10 },
  BD: { h: 'Martes Fin', k: 'tuesdayEnd', c: 'BD', i: 56, width: 10 },
  BE: { h: 'Miercoles Inicio', k: 'wednesdayStart', c: 'BE', i: 57, width: 10 },
  BF: { h: 'Miercoles Fin', k: 'wednesdayEnd', c: 'BF', i: 58, width: 10 },
  BG: { h: 'Jueves Inicio', k: 'thursdayStart', c: 'BG', i: 59, width: 10 },
  BH: { h: 'Jueves Fin', k: 'thursdayEnd', c: 'BH', i: 60, width: 10 },
  BI: { h: 'Viernes Inicio', k: 'fridayStart', c: 'BI', i: 61, width: 10 },
  BJ: { h: 'Viernes Fin', k: 'fridayEnd', c: 'BJ', i: 62, width: 10 },
  BK: { h: 'Sabado Inicio', k: 'saturdayStart', c: 'BK', i: 63, width: 10 },
  BL: { h: 'Sabado Fin', k: 'saturdayEnd', c: 'BL', i: 64, width: 10 },
  BM: { h: 'Domingo Inicio', k: 'sundayStart', c: 'BM', i: 65, width: 10 },
  BN: { h: 'Domingo Fin', k: 'sundayEnd', c: 'BN', i: 66, width: 10 },
}

export const columnsProfessionalsExcel = {
  A: {
    h: 'Sucursal DNI',
    k: 'dniSucursal',
    c: 'A',
    i: 1,
    requerido: true,
    tipoDato: tipoDato.NUMERICO,
    selectorSimple: false,
    parentCol: null,
    dvType: dataValidationExcel.INTEGER,
    dvPromptTitle: 'Número entero',
    dvPrompt: 'Debe ingresar un número entero',
  },
  B: {
    h: 'Activo',
    k: 'activo',
    c: 'B',
    i: 2,
    requerido: true,
    tipoDato: tipoDato.BOOLEANO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Boolean!$A$2:$A$100000',
  },
  C: {
    h: 'Nombre',
    k: 'nombre',
    c: 'C',
    i: 3,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: false,
    parentCol: null,
  },
  D: {
    h: 'Apellido',
    k: 'apellido',
    c: 'D',
    i: 4,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: false,
    parentCol: null,
  },
  E: {
    h: 'Email',
    k: 'email',
    c: 'E',
    i: 5,
    requerido: true,
    tipoDato: tipoDato.EMAIL,
    selectorSimple: false,
    parentCol: null,
  },
  F: {
    h: 'País',
    k: 'paisName',
    c: 'F',
    i: 6,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Pais!$A$2:$A$100000',
  },
  G: {
    h: 'País ID',
    k: 'pais',
    c: 'G',
    i: 7,
    requerido: true,
    parentCol: 'F',
    hidden: true,
    f: '=IFERROR(VLOOKUP($F$2:$F$100000,Pais!$A$2:$B$100000,2,FALSE),"")',
    dvType: dataValidationExcel.INTEGER,
  },
  H: {
    h: 'Tipo Documento',
    k: 'tipoIdname',
    c: 'H',
    i: 8,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'TipoDocumento!$A$2:$A$100000',
  },
  I: {
    h: 'Tipo Documento ID',
    k: 'tipoId',
    c: 'I',
    i: 9,
    requerido: true,
    parentCol: 'H',
    hidden: true,
    f: '=IFERROR(VLOOKUP($H$2:$H$100000,TipoDocumento!$A$2:$B$100000,2,FALSE),"")',
    dvType: dataValidationExcel.INTEGER,
  },
  J: {
    h: 'Número ID',
    k: 'numeroId',
    c: 'J',
    i: 10,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: false,
    parentCol: null,
  },
  K: {
    h: 'Teléfono',
    k: 'telefono',
    c: 'K',
    i: 11,
    requerido: true,
    tipoDato: tipoDato.TELEFONO,
    selectorSimple: false,
    parentCol: null,
  },
  L: {
    h: 'Dirección',
    k: 'direccion',
    c: 'L',
    i: 12,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: false,
    parentCol: null,
  },
  M: {
    h: 'Descuento Activo',
    k: 'descuentoActivo',
    c: 'M',
    i: 13,
    requerido: true,
    tipoDato: tipoDato.BOOLEANO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Boolean!$A$2:$A$100000',
  },
  N: {
    h: 'Tipo de descuento',
    k: 'tipoDescuento',
    c: 'N',
    i: 14,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'TipoDescuento!$A$2:$A$100000',
  },
  O: {
    h: 'Valor Descuento',
    k: 'valorDescuento',
    c: 'O',
    i: 15,
    requerido: true,
    tipoDato: tipoDato.NUMERICO,
    selectorSimple: false,
    parentCol: null,
    dvType: dataValidationExcel.DECIMAL,
  },
  P: {
    h: 'Monto Constituir',
    k: 'montoConstituir',
    c: 'P',
    i: 16,
    requerido: true,
    tipoDato: tipoDato.NUMERICO,
    selectorSimple: false,
    parentCol: null,
    dvType: dataValidationExcel.DECIMAL,
  },
  Q: {
    h: 'Monto Acumulado',
    k: 'montoAcumulado',
    c: 'Q',
    i: 17,
    requerido: true,
    tipoDato: tipoDato.NUMERICO,
    selectorSimple: false,
    parentCol: null,
    dvType: dataValidationExcel.DECIMAL,
  },
  R: {
    h: 'Banco',
    k: 'nombreBanco',
    c: 'R',
    i: 18,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Banco!$A$2:$A$100000',
  },
  S: {
    h: 'Tipo Cuenta',
    k: 'tipoCuenta',
    c: 'S',
    i: 19,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'TipoCuentaBancaria!$A$2:$A$100000',
  },
  T: {
    h: 'Número Cuenta',
    k: 'numeroBanco',
    c: 'T',
    i: 20,
    requerido: true,
    tipoDato: tipoDato.NUMERICO,
    selectorSimple: false,
    parentCol: null,
  },
  U: {
    h: 'Facturador',
    k: 'facturador',
    c: 'U',
    i: 21,
    requerido: true,
    tipoDato: tipoDato.BOOLEANO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'Boolean!$A$2:$A$100000',
  },
  V: {
    h: 'Retención Impuesto',
    k: 'retencionImpuesto',
    c: 'V',
    i: 22,
    requerido: true,
    tipoDato: tipoDato.NUMERICO,
    selectorSimple: false,
    parentCol: null,
    dvType: dataValidationExcel.DECIMAL,
  },
  W: {
    h: 'Otros Descuentos',
    k: 'otrosDescuentos',
    c: 'W',
    i: 23,
    requerido: true,
    tipoDato: tipoDato.NUMERICO,
    selectorSimple: false,
    parentCol: null,
    dvType: dataValidationExcel.DECIMAL,
  },
  X: {
    h: 'Razón Social',
    k: 'razonSocial',
    c: 'X',
    i: 24,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: false,
    parentCol: null,
  },
  Y: {
    h: 'Unidad de Negocio',
    k: 'businessUnit',
    c: 'Y',
    i: 25,
    requerido: true,
    tipoDato: tipoDato.TEXTO,
    selectorSimple: true,
    parentCol: null,
    dvType: dataValidationExcel.LIST,
    dvFormulae: 'UnidadNegocio!$A$2:$A$100000',
  },
  Z: {
    h: 'Unidad de Negocio ID',
    k: 'businessUnitId',
    c: 'Z',
    i: 26,
    requerido: true,
    parentCol: 'Y',
    hidden: true,
    f: '=IFERROR(VLOOKUP($Y$2:$Y$100000,UnidadNegocio!$A$2:$B$100000,2,FALSE),"")',
  },
}

export const columnsInstructions = {
  A: { h: 'Columna', k: 'column', c: 'A', i: 1 },
  B: { h: 'Requerido', k: 'requerido', c: 'B', i: 2 },
  C: { h: 'Tipo de dato', k: 'tipoDato', c: 'C', i: 3 },
  D: { h: 'Selector simple', k: 'selectorSimple', c: 'D', i: 4 },
}

export const typeOfLoadFiltered = permissions => {
  return typeOfLoads.filter(item => permissions.includes(item.permission))
}

/**
 * Objeto que posee los endpoint en función de
 * id de tipo de carga
 */

export const EndpointByTypeOfLoad = {
  1: professionalCharge,
  2: costConceptsCharge,
  4: branchOfficesSrvCharge,
  5: branchOfficesNeighborhoodCharge,
  6: uploadCCrBulkLoad,
}

const generateRowCells = (sheet, index, body, comunes, cities) => {
  let col = columnsProviderExcel
  let data = {
    razonSocial: {},
    nombreEmpresa: {},
    nombreFantasia: {},
    tipoId: {},
    numeroId: {},
    giro: {},
    numeroDeTrabajadores: {},
    bussinessUnit: {},
    comune: {},
    province: {},
    pais: {},
    direccion: {},
    longitude: {},
    latitude: {},
    nombreRepresentanteLegal: {},
    apellidoRepresentanteLegal: {},
    correoRepresentanteLegal: {},
    telefonoRepresentanteLegal: {},
    legalRepresentativeContactPhoneNumber: {},
    administrationName: {},
    administrationLastName: {},
    administrationEmail: {},
    phoneAdministration: {},
    contactPhoneAdministration: {},
    userNameAdmin: {},
    paymentEarly: {},
    mailPayment: {},
    modalityPayment: {},
    ownerName: {},
    bank: {},
    tipoCuenta: {},
    rut: {},
    bankDocumentType: {},
    bankDocumentNumber: {},
    branchOfficeAddress: {},
    contacName: {},
    firstLastNameContact: {},
    email: {},
    telefono: {},
    contactPhone: {},
    bussinessName: {},
    retentionType: {},
    retentionPercentage: {},
    mondayStart: {},
    mondayEnd: {},
    tuesdayStart: {},
    tuesdayEnd: {},
    wednesdayStart: {},
    wednesdayEnd: {},
    thursdayStart: {},
    thursdayEnd: {},
    fridayStart: {},
    fridayEnd: {},
    saturdayStart: {},
    saturdayEnd: {},
    sundayStart: {},
    sundayEnd: {},
    error: false,
  }
  sheet.getColumn(col.A.i).eachCell(c => {
    if (c.row === index) data.razonSocial = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.B.i).eachCell(c => {
    if (c.row === index) data.nombreEmpresa = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.C.i).eachCell(c => {
    if (c.row === index) data.nombreFantasia = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.E.i).eachCell(c => {
    if (c.row === index) data.tipoId = generateCell(c, 'complex', col.D.c)
  })
  sheet.getColumn(col.F.i).eachCell(c => {
    if (c.row === index) data.numeroId = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.G.i).eachCell(c => {
    if (c.row === index) data.giro = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.H.i).eachCell(c => {
    if (c.row === index)
      data.numeroDeTrabajadores = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.J.i).eachCell(c => {
    if (c.row === index) {
      data.bussinessUnit = {
        value: [{ businessUnit: generateCellValue(c, 'complex') }],
        address: c.address,
        parent: parent ? col.I.c + c.row : null,
      }
    }
  })
  sheet.getColumn(col.L.i).eachCell(c => {
    if (c.row === index) {
      let comuneObj = generateCell(c, 'complex', col.K.c)
      let comuneName = comunes.find(item => item.id === comuneObj.value)
      data.comune = { ...comuneObj, name: comuneName?.name }
    }
  })
  sheet.getColumn(col.N.i).eachCell(c => {
    if (c.row === index) {
      let cityObj = generateCell(c, 'complex', col.M.c)
      let cityName = cities.find(item => item.id === cityObj.value)
      data.province = { ...cityObj, name: cityName?.name }
    }
  })
  sheet.getColumn(col.P.i).eachCell(c => {
    if (c.row === index) data.pais = generateCell(c, 'complex', col.O.c)
  })
  sheet.getColumn(col.Q.i).eachCell(c => {
    if (c.row === index) data.direccion = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.R.i).eachCell(c => {
    if (c.row === index) data.longitude = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.S.i).eachCell(c => {
    if (c.row === index) data.latitude = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.T.i).eachCell(c => {
    if (c.row === index)
      data.nombreRepresentanteLegal = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.U.i).eachCell(c => {
    if (c.row === index)
      data.apellidoRepresentanteLegal = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.V.i).eachCell(c => {
    if (c.row === index)
      data.correoRepresentanteLegal = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.W.i).eachCell(c => {
    if (c.row === index)
      data.telefonoRepresentanteLegal = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.X.i).eachCell(c => {
    if (c.row === index)
      data.legalRepresentativeContactPhoneNumber = generateCell(
        c,
        'basic',
        null,
      )
  })
  sheet.getColumn(col.Y.i).eachCell(c => {
    if (c.row === index)
      data.administrationName = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.Z.i).eachCell(c => {
    if (c.row === index)
      data.administrationLastName = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AA.i).eachCell(c => {
    if (c.row === index)
      data.administrationEmail = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AB.i).eachCell(c => {
    if (c.row === index)
      data.phoneAdministration = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AC.i).eachCell(c => {
    if (c.row === index)
      data.contactPhoneAdministration = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AD.i).eachCell(c => {
    if (c.row === index) data.userNameAdmin = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AE.i).eachCell(c => {
    if (c.row === index) data.paymentEarly = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AF.i).eachCell(c => {
    if (c.row === index) data.mailPayment = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AG.i).eachCell(c => {
    if (c.row === index) data.modalityPayment = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AH.i).eachCell(c => {
    if (c.row === index) data.ownerName = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AJ.i).eachCell(c => {
    if (c.row === index) data.bank = generateCell(c, 'complex', col.AI.c)
  })
  sheet.getColumn(col.AL.i).eachCell(c => {
    if (c.row === index) data.tipoCuenta = generateCell(c, 'complex', col.AK.c)
  })
  sheet.getColumn(col.AM.i).eachCell(c => {
    if (c.row === index) data.rut = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AO.i).eachCell(c => {
    if (c.row === index)
      data.bankDocumentType = generateCell(c, 'complex', col.AN.c)
  })
  sheet.getColumn(col.AP.i).eachCell(c => {
    if (c.row === index)
      data.bankDocumentNumber = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AQ.i).eachCell(c => {
    if (c.row === index)
      data.branchOfficeAddress = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AR.i).eachCell(c => {
    if (c.row === index) data.contacName = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AS.i).eachCell(c => {
    if (c.row === index)
      data.firstLastNameContact = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AT.i).eachCell(c => {
    if (c.row === index) data.email = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AU.i).eachCell(c => {
    if (c.row === index) data.telefono = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AV.i).eachCell(c => {
    if (c.row === index) data.contactPhone = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AW.i).eachCell(c => {
    if (c.row === index) data.bussinessName = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AX.i).eachCell(c => {
    if (c.row === index) data.retentionTypeName = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.AY.i).eachCell(c => {
    if (c.row === index) data.retentionType = generateCell(c, 'complex', null)
  })
  sheet.getColumn(col.AZ.i).eachCell(c => {
    if (c.row === index)
      data.retentionPercentage = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BA.i).eachCell(c => {
    if (c.row === index) data.mondayStart = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BB.i).eachCell(c => {
    if (c.row === index) data.mondayEnd = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BC.i).eachCell(c => {
    if (c.row === index) data.tuesdayStart = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BD.i).eachCell(c => {
    if (c.row === index) data.tuesdayEnd = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BE.i).eachCell(c => {
    if (c.row === index) data.wednesdayStart = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BF.i).eachCell(c => {
    if (c.row === index) data.wednesdayEnd = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BG.i).eachCell(c => {
    if (c.row === index) data.thursdayStart = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BH.i).eachCell(c => {
    if (c.row === index) data.thursdayEnd = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BI.i).eachCell(c => {
    if (c.row === index) data.fridayStart = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BJ.i).eachCell(c => {
    if (c.row === index) data.fridayEnd = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BK.i).eachCell(c => {
    if (c.row === index) data.saturdayStart = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BL.i).eachCell(c => {
    if (c.row === index) data.saturdayEnd = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BM.i).eachCell(c => {
    if (c.row === index) data.sundayStart = generateCell(c, 'basic', null)
  })
  sheet.getColumn(col.BN.i).eachCell(c => {
    if (c.row === index) data.sundayEnd = generateCell(c, 'basic', null)
  })
  body.push(data)
}

const generateCellValue = (c, option) => {
  if (option === 'basic') {
    if (c.value !== undefined || c.value !== '') {
      return c.value?.hyperlink !== undefined ? c.value.text : c.value
    } else return null
  } else {
    if (c.value.result !== undefined) {
      return c.value.result.error === undefined ? c.value.result : null
    } else return null
  }
}

const generateCell = (c, option, parent) => {
  return {
    value: generateCellValue(c, option),
    address: c.address,
    parent: parent ? parent + c.row : parent,
  }
}

const filterEmptyRowsFromBody = body => {
  return body.filter(row => {
    for (let prop in row) {
      // columnsProviderExcel.J.k -> 'bussinessUnit'
      if (prop != columnsProviderExcel.J.k && row[prop].value) return true
      if (prop === columnsProviderExcel.J.k && row[prop].value[0].businessUnit)
        return true
    }
  })
}

export const importProviderExcelFile = async doc => {
  const wb = new ExcelJS.Workbook()
  const reader = new FileReader()
  let body = []
  reader.readAsArrayBuffer(doc)
  const result = await new Promise((resolve, reject) => {
    reader.onload = () => {
      const buffer = reader.result
      wb.xlsx.load(buffer).then(workbook => {
        workbook.eachSheet(sheet => {
          if (sheet.name === 'Proveedores') {
            let comunes = [],
              cities = []
            getComunesAndCitiesValues(workbook, comunes, cities)
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex > 1) {
                generateRowCells(sheet, rowIndex, body, comunes, cities)
              } else console.log(row.values)
            })
            resolve(filterEmptyRowsFromBody(body))
          }
        })
        reject('Error')
      })
    }
  })
  if (result) return { success: true, body: result }
  else return { success: false, body: result }
}

export const importProfessionalExcelFile = async doc => {
  const wb = new ExcelJS.Workbook()
  const reader = new FileReader()
  reader.readAsArrayBuffer(doc)

  let bodyCellProfessionalList = []
  let businessUnitList = []
  let cellsWithErrors = []
  let rowsWithErrors = []

  let countCellsWithFormula = 0
  let businessUnitIdColumn
  let parentColList = {}
  for (let prop in columnsProfessionalsExcel) {
    const index = columnsProfessionalsExcel[prop].i
    if (columnsProfessionalsExcel[prop].f) countCellsWithFormula++
    if (columnsProfessionalsExcel[prop].k === 'businessUnitId')
      businessUnitIdColumn = index
    if (columnsProfessionalsExcel[prop].parentCol)
      parentColList[index] = columnsProfessionalsExcel[prop].parentCol
  }

  const result = await new Promise((resolve, reject) => {
    reader.onload = () => {
      const buffer = reader.result
      wb.xlsx.load(buffer).then(workbook => {
        workbook.eachSheet(sheet => {
          if (sheet.name === professionalsSheetName) {
            sheet.eachRow((row, rowIndex) => {
              if (rowIndex == 1) {
                const validation = validateProfessionalsExcelHeaders(row._cells)
                if (!validation.sucess)
                  reject({
                    message: errorMessages.HEADERS_NOT_FOUNT,
                    headersDontFound: validation.headersDontFound,
                  })
              } else {
                const resultRow = isValidRowWithData(
                  row,
                  countCellsWithFormula,
                  businessUnitIdColumn,
                )
                if (
                  resultRow.isValid &&
                  !businessUnitList.includes(resultRow.businessUnitId)
                )
                  businessUnitList.push(resultRow.businessUnitId)

                if (resultRow.cellsWithErrors?.length > 0) {
                  if (!rowsWithErrors.includes(row.number))
                    rowsWithErrors.push(row.number)

                  cellsWithErrors = [
                    ...cellsWithErrors,
                    ...resultRow.cellsWithErrors,
                  ]
                }
                if (resultRow.data)
                  bodyCellProfessionalList.push(resultRow.data)
              }
            })
            resolve({
              bodyCellProfessionalList,
              businessUnitList,
              cellsWithErrors,
            })
          }
        })
        reject('Error')
      })
    }
  })
  if (result) return { success: true, body: result, rowsWithErrors }
  else return { success: false, body: result }
}

const getComunesAndCitiesValues = (workbook, comunes, cities) => {
  workbook.eachSheet(dataSheet => {
    if (dataSheet.name === 'Comuna') {
      dataSheet.eachRow((row, rowIndex) => {
        if (rowIndex > 1) {
          generateRowCellsComunes(dataSheet, rowIndex, comunes)
        } else console.log(row.values)
      })
    }
  })
  workbook.eachSheet(dataSheet => {
    if (dataSheet.name === 'Region') {
      dataSheet.eachRow((row, rowIndex) => {
        if (rowIndex > 1) {
          generateRowCellsCities(dataSheet, rowIndex, cities)
        } else console.log(row.values)
      })
    }
  })
}

const generateRowCellsComunes = (sheet, index, comunes) => {
  let comune = {
    id: null,
    name: null,
  }
  sheet.getColumn(1).eachCell(c => {
    if (c.row === index) comune.name = getNameComuneOrCityName(c.value)
  })
  sheet.getColumn(2).eachCell(c => {
    if (c.row === index) comune.id = c.value
  })
  comunes.push(comune)
}

const getNameComuneOrCityName = value => {
  if (value && typeof value === 'string') return value.split('-')[0].trim()
  return ''
}

const generateRowCellsCities = (sheet, index, cities) => {
  let city = {
    id: null,
    name: null,
  }
  sheet.getColumn(1).eachCell(c => {
    if (c.row === index) city.name = getNameComuneOrCityName(c.value)
  })
  sheet.getColumn(2).eachCell(c => {
    if (c.row === index) city.id = c.value
  })
  cities.push(city)
}

/**
 * Valida que la plantilla de excel tenga todas las columnas correspondientes
 * y en su respectiva posicion
 * @param {*} headersRow
 * @returns
 */
const validateProfessionalsExcelHeaders = headersRow => {
  const headersDontFound = []
  const headers = headersRow.map(cell => ({
    value: cell.value,
    col: cell.col,
  }))

  for (let prop in columnsProfessionalsExcel) {
    const header = columnsProfessionalsExcel[prop].h
    const headerColNumber = columnsProfessionalsExcel[prop].i
    const column = headers.find(
      cell => cell.value === header && cell.col === headerColNumber,
    )
    if (!column) headersDontFound.push(header)
  }

  return headersDontFound.length != 0
    ? { sucess: false, headersDontFound }
    : { sucess: true }
}

/**
 * Valida la informacion de la fila y asigna errores en caso de ser necesario
 * @param {*} row
 * @param {*} countCellsWithFormula
 * @param {*} businessUnitIdColumn
 * @param {*} parentColList
 * @returns
 */
const isValidRowWithData = (
  row,
  countCellsWithFormula,
  businessUnitIdColumn,
) => {
  if (row.actualCellCount <= countCellsWithFormula) return { isValid: false }
  let businessUnitId
  let data = { row: row.number }
  let errors = []

  for (let i = 1; i <= Object.keys(columnsProfessionalsExcel).length; i++) {
    const cell = row.getCell(i)

    for (let prop in columnsProfessionalsExcel) {
      const col = columnsProfessionalsExcel[prop].i
      if (cell.col !== col) continue

      const required = columnsProfessionalsExcel[prop].requerido
      const key = columnsProfessionalsExcel[prop].k
      const address = cell.address
      const parent = columnsProfessionalsExcel[prop].parentCol
        ? columnsProfessionalsExcel[prop].parentCol + cell.row
        : null
      const { value, error } = isErrorInCell(cell, required)

      if (col === businessUnitIdColumn && !error) businessUnitId = value
      if (error) errors.push({ error, address, parent })

      data[key] = {
        address,
        value,
        required,
        error,
        parent,
      }
    }
  }

  const isValid = errors.length <= 0
  return { isValid, data, businessUnitId, cellsWithErrors: errors }
}

/**
 * Valida si, en caso de ser requerido el valor, sea diferente de null.
 * Tambien valida que el tipo de valor de la celda sea el mismo que requerido
 * @param {*} cell
 * @param {*} required
 * @param {*} valueType
 * @returns
 */
const isErrorInCell = (cell, required) => {
  let error = null
  let value =
    cell.result ||
    (cell.type == ExcelJS.ValueType.Formula ||
    cell.type == ExcelJS.ValueType.Hyperlink
      ? cell.text
      : cell.value)

  if (required && !value && value !== 0)
    error = errorMessages.ERROR_VALUE_REQUIRED

  if (value === 'TRUE') value = true
  if (value === 'FALSE') value = false

  return { value, error }
}
