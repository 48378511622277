import { api } from './interceptors'
import { buildHeader } from './basicParams'
const API_URL = process.env.REACT_APP_AIR_API_URL

const getBusinessUnit = async () => {
  const options = buildHeader()
  return api.get(
    `${API_URL}/LoginREST/rest/login/business/unit/by/user`,
    options,
  )
}

export { getBusinessUnit }
