import { api } from './interceptors'
import { buildHeader } from './basicParams'
const API_URL = process.env.REACT_APP_AIR_API_URL

const getCountries = async () => {
  const options = buildHeader()
  try {
    return await api.get(`${API_URL}/UbigeoREST/location/country`, options)
  } catch (e) {
    console.error(e)
  }
}

export { getCountries }
