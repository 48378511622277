import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  loading: {
    display: 'none',
  },
  dropzone: {
    backgroundColor: '#FFFFFF',
    border: '1px dashed rgba(0, 0, 0, 0.26)',
    maxWidth: '1410px',
    height: '207px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#3D3F4C',
  },
  dropTitle: {
    fontSize: '18px',
    lineHeight: '160%',
    fontWeight: '500',
  },
  dropText: {
    fontSize: '14px',
    lineHeight: '157%',
    fontWeight: '500',
  },
  dropInfo: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 'normal',
  },
  sendingFile: {
    marginTop: '10px',
    marginRight: '50px',
    float: 'right',
  },
  tableBody: {
    marginBottom: '15px',
  },
  selectUpload: {
    minWidth: '197px',
    maxWidth: '197px',
  },
}))
