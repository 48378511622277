const defMax = 50
export const defaultTextInput = {
  disabled: false,
  required: true,
  maxLength: defMax,
  type: 'text',
}
export const defaultNumberInput = {
  disabled: false,
  required: true,
  maxLength: defMax,
  type: 'number',
}
export const defaultPhoneInput = {
  disabled: false,
  required: true,
  maxLength: 20,
  type: 'phone',
}

export const defaultColumn = {
  visible: true,
  sort: true,
}
