// @flow
import { createContext, useState } from 'react'
import {
  createTheme,
  ThemeProvider as ThemeProviderInner,
} from '@material-ui/core/styles'
import { defaultTheme } from '../configUser'
import { transparentColor } from '../util/transparentColor'

const ThemeContext = createContext()
const ThemeProvider = ({ children }) => {
  const [themeCustom, setThemeCustom] = useState({
    primary: 'rgba(0, 47, 197, 1)',
    secondary: 'rgba(0, 47, 197, 1)',
    highContrast: false,
  })
  const theme = createTheme({
    ...defaultTheme,
    colors: {
      mainDark: '#171737',
      mainLight: '#2A2A65',
      mainBlue: '#00AEC7',
      white: '#fff',
      main: '#0033A0',
      darkBlue: '#299DBA',
      subtitleDark: 'rgba(0, 0, 0, 0.87)',
      bluePurple: '#002FC5',
    },
    fonts: {
      mainFont: 'Poppins-Regular',
    },
    palette: {
      highContrast: themeCustom.highContrast,
      primary: {
        main: themeCustom.primary,
        contrastText: themeCustom.contrastText,
        light: transparentColor(themeCustom.primary, 0.2),
      },
      text: {
        disabled: 'rgba(0, 0, 0, 0.38)',
      },
      error: {
        main: 'rgba(244, 67, 54, 1)',
      },
      secondary: {
        main: themeCustom.secondary,
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          fontSize: '15px',
        },
      },
    },
  })
  return (
    <ThemeContext.Provider value={[themeCustom, setThemeCustom]}>
      <ThemeProviderInner theme={theme}>{children}</ThemeProviderInner>
    </ThemeContext.Provider>
  )
}
export { ThemeContext, ThemeProvider }
