import * as zoid from 'zoid/dist/zoid.frameworks'

let MassiveUploads = zoid.create({
  tag: 'massive-uploads',
  dimensions: {
    width: '100%',
    height: '100%',
  },
})

export { MassiveUploads }
