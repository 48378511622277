import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, TextField } from '@material-ui/core'
import {
  inputPropsDefault,
  useStylesInputLabel,
} from '../../../util/inputPropsDefault'

const useStyles = makeStyles(() => ({
  asterikColor: {
    color: '#f44336',
  },
  textField: {
    // minWidth: 280,
    textAlign: 'left',
    float: 'left',
  },
  rootLabel: {
    color: 'rgba(0, 0, 0, 0.75) !important',
  },
}))

export default function Select({
  id,
  label,
  value,
  onChange,
  error,
  data,
  required,
  fullWidth,
  disabled,
  placeholder,
  helperText,
  allValue,
  size,
  inputRef,
}) {
  const classes = useStyles()
  const classesInput = useStylesInputLabel()
  return (
    <TextField
      id={id}
      select
      label={label}
      variant="outlined"
      value={value || placeholder}
      onChange={onChange}
      error={!!error}
      helperText={error ? getError(error, helperText) : null}
      required={required}
      fullWidth={fullWidth}
      className={`${classes.textField}`}
      InputLabelProps={{
        ...inputPropsDefault(classesInput),
      }}
      disabled={disabled}
      inputRef={inputRef}
      size={size}
    >
      {placeholder ? (
        <MenuItem value={placeholder} disabled>
          {placeholder}
        </MenuItem>
      ) : null}
      {data.map(option => {
        return (
          <MenuItem
            key={option.value}
            value={allValue ? option : option.value}
            option={option}
            id={id}
          >
            {option.label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

function getError(error, helperText) {
  return error.message ? error.message : helperText
}
