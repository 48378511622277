import { DialogTitle, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const BootstrapDialogTitle = props => {
  const { children, handleClose, other } = props
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '2rem',
          fontFamily: 'Roboto',
        }}
      >
        {children}
        {handleClose ? (
          <div
            style={{
              width: '2rem',
              display: 'flex',
              fontFamily: 'Roboto',
              justifyContent: 'flex-start',
              alignItems: 'flex-start !important',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : null}
      </div>
    </DialogTitle>
  )
}

export default BootstrapDialogTitle
