import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import {
  columnsProviderExcel,
  columnsProfessionalsExcel,
  columnsInstructions as colInstructions,
  dataValidationExcel,
} from '../TemplateUpload/constants'

const defaultColumns = [
  { header: 'Nombre', key: 'name', width: 40 },
  { header: 'Id', key: 'id', width: 8 },
]

const documenTypesColumns = [
  ...defaultColumns,
  { header: 'Country', key: 'country', width: 40 },
]

const CHILE = 'Chile'

export const professionalsSheetName = 'Profesionales'

export const generateProvidersExcelFile = async data => {
  const col = columnsProviderExcel
  const wb = new ExcelJS.Workbook()
  const ws = wb.addWorksheet('Proveedores')
  const ws1 = wb.addWorksheet('UnidadNegocio')
  const ws2 = wb.addWorksheet('TipoDocumento')
  const ws3 = wb.addWorksheet('Banco')
  const ws4 = wb.addWorksheet('TipoCuentaBancaria')
  const ws5 = wb.addWorksheet('Pais')
  const ws6 = wb.addWorksheet('Region')
  const ws7 = wb.addWorksheet('Comuna')
  const ws8 = wb.addWorksheet('Retencion')
  const ws9 = wb.addWorksheet('Boolean')
  const defaultRowCounts = 100

  // Set columns & rows
  let columnsWs = []
  let row = {}
  for (let prop in col) {
    // Col - Header
    columnsWs.push({
      header: col[prop].h,
      key: col[prop].k,
      hidden: col[prop].hidden,
      width: col[prop].width || 30,
    })

    // Row
    row[col[prop].k] = col[prop].f ? { formula: col[prop].f } : null
  }
  // Set columns
  ws.columns = columnsWs

  // Set Rows
  for (let i = 0; i < defaultRowCounts; i++) {
    ws.addRow(row)
  }

  // Set validations
  for (let prop in col) {
    setValidationsEachCell(ws, col[prop])
  }

  // Ocultar columnas de retención si no aplica para Chile
  if (data?.countries.filter(element => element.name === CHILE).length === 0) {
    ws.getColumn(col.AX.k).hidden = true
    ws.getColumn(col.AY.k).hidden = true
    ws.getColumn(col.AZ.k).hidden = true
  }

  //#region BusinessUnits
  ws1.columns = [
    { header: 'Nombre', key: 'name', width: 40 },
    { header: 'Id', key: 'id', width: 8 },
  ]
  data.buList.forEach(i => {
    ws1.addRow({ id: i.uuid, name: i.name })
  })
  ws1.protect(null, { selectLockedCells: true, selectUnlockedCells: false })
  //#endregion BusinessUnits

  //#region DocumentTypes
  ws2.columns = [
    { header: 'Nombre', key: 'name', width: 40 },
    { header: 'Id', key: 'id', width: 8 },
  ]
  data.documentTypes.forEach(i => {
    ws2.addRow({ id: i.id, name: i.name })
  })
  ws2.protect(null, { selectLockedCells: true, selectUnlockedCells: false })
  //#endregion DocumentTypes

  //#region Banks
  ws3.columns = [
    { header: 'Nombre', key: 'name', width: 40 },
    { header: 'Id', key: 'id', width: 8 },
  ]
  data.banks.forEach(c => {
    ws3.addRow({ id: c.id, name: c.name })
  })
  ws3.protect(null, { selectLockedCells: true, selectUnlockedCells: false })
  //#endregion Banks

  //#region AccountBankTypes
  ws4.columns = [
    { header: 'Nombre', key: 'name', width: 40 },
    { header: 'Id', key: 'id', width: 8 },
  ]
  data.accountTypesBank.forEach(i => {
    ws4.addRow({ id: i.id, name: i.name })
  })
  ws4.protect(null, { selectLockedCells: true, selectUnlockedCells: false })
  //#endregion AccountBankTypes

  //#region Countries
  ws5.columns = [
    { header: 'Nombre', key: 'name', width: 40 },
    { header: 'Id', key: 'id', width: 8 },
  ]
  data.countries.forEach(i => {
    ws5.addRow({ id: i.id, name: i.name })
  })
  ws5.protect(null, { selectLockedCells: true, selectUnlockedCells: false })
  //#endregion Countries

  //#region Cities
  ws6.columns = [
    { header: 'Nombre', key: 'name', width: 40 },
    { header: 'Id', key: 'id', width: 8 },
  ]
  data.countries.forEach(c => {
    c.cities.forEach(i => {
      ws6.addRow({ id: i.id, name: i.name })
    })
  })
  ws6.protect(null, { selectLockedCells: true, selectUnlockedCells: false })
  //#endregion Cities

  //#region Communes
  ws7.columns = [
    { header: 'Nombre', key: 'name', width: 40 },
    { header: 'Id', key: 'id', width: 8 },
  ]
  data.countries.forEach(c => {
    c.cities.forEach(cc => {
      cc.communes.forEach(i => {
        ws7.addRow({ id: i.id, name: i.name })
      })
    })
  })
  ws7.protect(null, { selectLockedCells: true, selectUnlockedCells: false })
  //#endregion Communes

  // Retencion - START
  generateWorksheetForSimpleSelect(ws8, data.retentionTypes, null)
  // Retencion - END

  generateWorksheetForSimpleSelect(ws9, data.boolean, [
    { header: 'Nombre', key: 'name', width: 40 },
  ])

  const generationTime = generateDateFirm()

  return wb.xlsx
    .writeBuffer()
    .then(async buffer => {
      await FileSaver.saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `CargaMasivaProveedores_${generationTime}.xlsx`,
      )
      return {
        error: false,
        empty: false,
        message: `CargaMasivaProveedores_${generationTime}.xlsx`,
      }
    })
    .catch(err => {
      return { error: true, empty: false, message: err }
    })
}

export const generateProfessionalsExcelFile = async data => {
  const col = columnsProfessionalsExcel
  const wb = new ExcelJS.Workbook()
  const ws = wb.addWorksheet(professionalsSheetName)
  const ws1 = wb.addWorksheet('Instrucciones')
  const ws2 = wb.addWorksheet('UnidadNegocio')
  const ws3 = wb.addWorksheet('TipoDocumento')
  const ws4 = wb.addWorksheet('Banco')
  const ws5 = wb.addWorksheet('TipoCuentaBancaria')
  const ws6 = wb.addWorksheet('Pais')
  const ws7 = wb.addWorksheet('TipoDescuento')
  const ws8 = wb.addWorksheet('Boolean')
  const defaultRowCounts = 100

  // Set columns & rows
  let columnsWs = []
  let row = {}
  for (let prop in col) {
    // Col - Header
    columnsWs.push({
      header: col[prop].h,
      key: col[prop].k,
      hidden: col[prop].hidden,
      width: 20,
    })

    // Set headers comments
    const text = col[prop].requerido
      ? `${col[prop].tipoDato} (Requerido)`
      : col[prop].tipoDato

    ws.getCell(prop + '1').note = {
      protection: {
        locked: true,
        lockText: true,
      },
      margins: {
        insetmode: 'custom',
        inset: [0.25, 0.25, 0.35, 0.35],
      },
      texts: [{ text }],
    }

    // Row
    row[col[prop].k] = col[prop].f ? { formula: col[prop].f } : null
  }
  // Set columns
  ws.columns = columnsWs

  // Set Rows
  for (let i = 0; i < defaultRowCounts; i++) {
    ws.addRow(row)
  }

  // Set validations
  for (let prop in col) {
    setValidationsEachCell(ws, col[prop])
  }

  // Worksheet - Instrucciones
  ws1.columns = [
    { header: colInstructions.A.h, key: colInstructions.A.k, width: 30 },
    { header: colInstructions.B.h, key: colInstructions.B.k, width: 15 },
    { header: colInstructions.C.h, key: colInstructions.C.k, width: 15 },
    { header: colInstructions.D.h, key: colInstructions.D.k, width: 15 },
  ]

  for (let prop in col) {
    if (
      col[prop].k !== 'pais' &&
      col[prop].k !== 'tipoId' &&
      col[prop].k !== 'businessUnitId'
    ) {
      ws1.addRow({
        column: col[prop].h,
        requerido: col[prop].requerido ? 'Si' : 'No',
        tipoDato: col[prop].tipoDato,
        selectorSimple: col[prop].selectorSimple ? 'Si' : 'No',
      })
    }
  }

  // Worksheet - UnidadNegocio
  const buList = data.buList.map(bu => ({ id: bu.uuid, name: bu.name }))
  generateWorksheetForSimpleSelect(ws2, buList, null)
  // Worksheet - TipoDocumento
  generateWorksheetForSimpleSelect(ws3, data.documentTypes, documenTypesColumns)
  // Worksheet - Banco
  generateWorksheetForSimpleSelect(ws4, data.banks, [
    { header: 'Nombre', key: 'name', width: 40 },
  ])
  // Worksheet - TipoCuentaBancaria
  generateWorksheetForSimpleSelect(ws5, data.accountTypesBank, null)
  // Worksheet - Pais
  generateWorksheetForSimpleSelect(ws6, data.countries, null)
  // Worksheet - TipoDescuento
  generateWorksheetForSimpleSelect(ws7, data.discountTypes, [
    { header: 'Nombre', key: 'name', width: 40 },
  ])
  // Worksheet - Boolean
  generateWorksheetForSimpleSelect(ws8, data.boolean, [
    { header: 'Nombre', key: 'name', width: 40 },
  ])

  const generationTime = generateDateFirm()

  return wb.xlsx
    .writeBuffer()
    .then(async buffer => {
      await FileSaver.saveAs(
        new Blob([buffer], { type: 'application/octet-stream' }),
        `CargaMasivaProfesionales_${generationTime}.xlsx`,
      )
      return {
        error: false,
        empty: false,
        message: `CargaMasivaProfesionales_${generationTime}.xlsx`,
      }
    })
    .catch(err => {
      return { error: true, empty: false, message: err }
    })
}

const generateWorksheetForSimpleSelect = (worksheet, list, columns) => {
  worksheet.columns = columns || defaultColumns

  list.forEach(element => {
    worksheet.addRow({
      id: element.id,
      name: element.name,
      country: element.country,
    })
  })

  worksheet.protect(null, {
    selectLockedCells: true,
    selectUnlockedCells: false,
  })
}

const setValidationsEachCell = (worksheet, col) => {
  worksheet.getColumn(col.k).eachCell(cell => {
    if (cell.address !== col.c + '1') {
      let props = {}
      if (col.dvPromptTitle && col.dvPrompt) {
        props.showInputMessage = true
        props.promptTitle = col.dvPromptTitle
        props.prompt = col.dvPrompt
      }
      if (col.dvType === dataValidationExcel.LIST) {
        props.ref = col.dvFormulae
        props.formulae = [col.dvFormulae]
      }

      cell.dataValidation = {
        type: col.dvType || dataValidationExcel.TEXT,
        showErrorMessage: true,
        allowBlank: !col.requerido,
        ...props,
      }
    }
  })
}

/**
 * Obtiene la fecha en un formato establecido
 * @returns
 */
const generateDateFirm = () => {
  const date = new Date()
  const year = date.getFullYear()
  const month = getNumberWithZero(date.getMonth())
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = getNumberWithZero(date.getMinutes())
  const seconds = getNumberWithZero(date.getSeconds())

  return `${year}${month}${day}_${hours}${minutes}${seconds}`
}

const getNumberWithZero = num => {
  return num + 1 < 10 ? `0${num + 1}` : num + 1
}
