import MassiveUploadsMaintainer from './pages/MassiveUploadsMaintainer'

const routes = [
  {
    path: '/',
    name: 'providers',
    exact: true,
    Component: MassiveUploadsMaintainer,
    permission: null,
  },
  // {
  //   path: '/provider/:id',
  //   name: 'edit-provider',
  //   Component: CreateProvider,
  //   permission: dictionary.provider.editProvider,
  // },
  // {
  //   path: '/create',
  //   name: 'create-provider',
  //   Component: CreateProvider,
  //   permission: dictionary.provider.createProvider,
  // },
  // {
  //   path: '/branchoffices/:id',
  //   name: 'Branchoffices',
  //   Component: Branchoffices,
  //   permission: dictionary.provider.editProviderBranch,
  // },
]

export { routes }
