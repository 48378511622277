/*
 *
 * LanguageProvider reducer
 *
 */
import produce from 'immer'

import { CHANGE_LOCALE, CHANGE_TENANT } from './constants'

export const initialState = {
  locale: window.localStorage.getItem('locale')
    ? window.localStorage.getItem('locale')
    : 'es',
  tenant: null,
}

export const initalAction = {
  type: CHANGE_LOCALE,
  locale: 'de',
}

/* eslint-disable default-case, no-param-reassign */
const languageProviderReducer = (state = initialState, action = initalAction) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_LOCALE:
        draft.locale = action.locale
        window.localStorage.setItem('locale', action.locale)
        break
      case CHANGE_TENANT:
        draft.tenant = action.tenant
        break
    }
  })

export default languageProviderReducer
