import { makeStyles } from '@material-ui/core/styles'

// @ts-ignore
export const useStylesInputLabel = makeStyles(theme => ({
  asteriskColor: {
    color: 'rgba(245, 0, 87, 1)',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `${
        theme.palette.highContrast ? theme.palette.primary.main : '#000'
      } !important`,
    },
  },
  cssLabel: {
    fontWeight: '400',
  },
  cssFocused: {
    color: `${
      theme.palette.highContrast ? theme.palette.primary.main : '#000'
    } !important`,
  },
  cssError: {
    color: `${theme.palette.error.main} !important`,
  },
}))

export const inputPropsDefault = (classes: any) => {
  return {
    shrink: true,
    classes: {
      // @ts-ignore
      asterisk: classes.asteriskColor,
      // @ts-ignore
      root: classes.cssLabel,
      // @ts-ignore
      focused: classes.cssFocused,
      error: classes.cssError,
    },
  }
}
