import React, { useState } from 'react'

import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { any, func, number } from 'prop-types'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useStyles } from './styles'

const TutenTableActions = props => {
  const classes = useStyles()
  const { actions, row, active, index } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    active(index, true)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    active(index, false)
    setAnchorEl(null)
  }

  const handleAction = (action, rowData, close = true) => {
    // eslint-disable-next-line no-empty
    action(rowData)
    if (close) handleClose()
  }

  return (
    <div
      className="actions"
      style={{ visibility: anchorEl ? 'visible' : 'hidden' }}
    >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {actions(row)
          .filter(({ visible = true }) => visible)
          .map(({ text, action, close }, i) => (
            <MenuItem key={i} onClick={() => handleAction(action, row, close)}>
              <ListItemText
                classes={{ primary: classes.actionText }}
                primary={text}
              />
            </MenuItem>
          ))}
      </Menu>
    </div>
  )
}

TutenTableActions.propTypes = {
  active: func,
  actions: func,
  index: number,
  row: any,
}

export { TutenTableActions }
