import React, { useEffect, useState } from 'react'
import { any, array, bool, func, node, oneOfType, string } from 'prop-types'
import { useStyles, useclasses } from './styles'
import { TutenTableActions } from './Actions/index'
import TablePagination from '@material-ui/core/TablePagination'
import Checkbox from '@material-ui/core/Checkbox'
import './tutenTable.css'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import noData from '../../assets/images/no-data-found.svg'
import SearchBar from 'material-ui-search-bar'
import { MaterialButton } from '../atomComponents/Buttons'
import SaveAltIcon from '@material-ui/icons/SaveAlt'

const nextOrder = order => {
  if (order === 0) return 1
  if (order === 1) return 2
  if (order === 2) return 0
}

const TableColHeader = props => {
  const classes = useStyles()
  const { sort = false, text, handleSort, orderBy, order, field } = props
  if (!sort || !field)
    return <TableCell className={classes.text}>{text}</TableCell>

  return (
    <TableCell
      className={`${classes.sortable} ${classes.text}`}
      onClick={() => handleSort(field, nextOrder(order))}
      style={{
        fontWeight: '700 !important',
      }}
    >
      {text}
      <ArrowDownwardIcon
        className={classes.icon}
        style={{
          visibility: orderBy != field || order == 2 ? 'hidden' : 'visible',
          transform: `rotate(${order < 2 ? order * 180 : 0}deg)`,
        }}
      />
    </TableCell>
  )
}

TableColHeader.propTypes = {
  sort: bool,
  text: oneOfType([string, node]),
  handleSort: func,
  orderBy: string,
  order: string,
  field: string,
}

const TutenTable = props => {
  const classes = useStyles()
  const [currentSorting, setCurrentSorting] = useState()
  const [order, setOrder] = useState()
  const [selectedID, setSelectedID] = useState(null)
  const [total, setTotal] = useState(0)

  let {
    actions,
    recordsLabel,
    searchActive,
    rowIsChecked,
    setRowIsChecked,
    checkBoxActive,
    getData,
    columns = [],
    showPagination = true,
    filters,
    elevation,
    fieldToActive,
    refresh,
    getSnackbar,
    clickData,
    professionals,
    editBranchoffice,
    showDownload,
    downloadDoc,
    isIconDisabled,
    setIsIconDisabled,
    showIconWaiting,
    setShowIconWaiting,
  } = props
  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searched, setSearched] = useState('')
  const [searchedSubmit] = useState('')
  const [loadingData, setLoadingData] = useState(true)

  const [clickedRow, setClickedRow] = useState(null)

  const setupData = async (
    filter,
    pageData = 0,
    limit = 5,
    field = null,
    orderData = null,
  ) => {
    let res = await getData(pageData, limit, field, orderData, filter, setPage)
    try {
      const { totalData, items } = res
      setTotal(totalData)
      setTableData(items.map(row => ({ ...row, hoverEffect: false })))
      if (items) setLoadingData(false)
    } catch (e) {
      if (res?.status === 403) setTotal(0)
      setLoadingData(false)
      getSnackbar && getSnackbar()
    }
  }
  const requestSearch = searchedVal => {
    setSearched(searchedVal)
  }

  const refreshTable = async () => {
    setupData(undefined, 0, rowsPerPage, currentSorting, order)
    setPage(0)
  }

  const cancelSearch = () => {
    setSearched('')
    requestSearch(searched)
  }

  const refreshData = async () => {
    setupData(searched, page + 1, rowsPerPage, currentSorting, order)
    setPage(page)
  }

  const handleSort = (field, newOrder) => {
    setLoadingData(true)
    let od = newOrder
    let fi = field

    if (field === currentSorting) {
      if (newOrder == 2) {
        od = null
        fi = null
      }
      setOrder(newOrder)
    } else {
      setCurrentSorting(field)
      setOrder(0)
      od = 0
    }
    setPage(0)
    setupData(undefined, 0, rowsPerPage, fi, od)
  }

  const handleChangePage = async (event, newPage) => {
    setLoadingData(true)
    setupData(undefined, newPage + 1, rowsPerPage, currentSorting, order)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = async event => {
    const rows = parseInt(event.target.value, 10)
    setRowsPerPage(rows)
    setLoadingData(true)
    const { totalItems, items } = await getData(
      0,
      rows,
      currentSorting,
      order,
      searched,
    )
    if (items) setLoadingData(false)
    setTotal(totalItems)
    setTableData(items)
    setPage(0)
  }

  const activeRow = (index, isActive) => {
    const copy = JSON.parse(JSON.stringify(tableData))
    copy[index].hoverEffect = isActive
    setTableData(copy)
  }

  const onClickDownload = row => {
    setClickedRow(row.id)
    setIsIconDisabled(true)
    setShowIconWaiting(true)
    downloadDoc(row)
  }

  useEffect(() => {
    setLoadingData(true)
    refreshTable()
  }, [filters])

  useEffect(() => {
    refreshData()
  }, [refresh, searchedSubmit])
  return (
    <>
      <Paper elevation={elevation}>
        {searchActive && (
          <>
            <SearchBar
              style={{
                minWidth: 800,
                marginLeft: 16,
                float: 'left',
              }}
              value={searched}
              onChange={searchVal => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder={'Buscar por No. documento'}
            />
            <div className="searchButton">
              <MaterialButton
                type={props.type}
                text={'BUSCAR'}
                variant={'outlined'}
                style={{
                  float: 'left',
                  color: '#002FC5',
                  marginLeft: '20px',
                }}
                onClick={() => {
                  refreshData()
                }}
              />
            </div>
          </>
        )}
        <TableContainer>
          <Table className={classes.table} aria-label="tuten table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    width: 45,
                  }}
                />
                {columns
                  .filter(({ visible }) => visible)
                  .map(({ text, sort = false, field }, index) => (
                    <TableColHeader
                      key={index}
                      sort={sort}
                      text={text}
                      field={field}
                      orderBy={currentSorting}
                      order={order}
                      handleSort={handleSort}
                    />
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingData &&
                tableData.map((row, rowIndex) => (
                  <TableRow
                    hover
                    aria-checked={
                      row.id
                        ? selectedID === row.id
                        : selectedID === row.professionalId
                    }
                    onClick={() => {
                      setSelectedID(row.id ? row.id : row.professionalId)
                    }}
                    selected={
                      row.id
                        ? selectedID === row.id
                        : selectedID === row.professionalId
                    }
                    key={`${(row.name ? row.name : '') + rowIndex + 1}`}
                    className={`${classes.row}  ${classes.tableRow}`}
                    classes={{ hover: classes.hover }}
                  >
                    <TableCell padding="checkbox" className={classes.cell}>
                      {showDownload && (
                        <Tooltip
                          title={
                            isIconDisabled === true && row.id !== clickedRow
                              ? 'Podrá iniciar otra descarga una vez finalizada la actual.'
                              : ''
                          }
                          arrow
                        >
                          <IconButton
                            variant="contained"
                            onClick={() => {
                              onClickDownload(row)
                            }}
                            disabled={isIconDisabled && row.id !== clickedRow}
                            className={classes.iconContainer}
                          >
                            {!showIconWaiting && <SaveAltIcon />}

                            {showIconWaiting && row.id !== clickedRow && (
                              <SaveAltIcon />
                            )}

                            {showIconWaiting && row.id === clickedRow && (
                              <CircularProgress size={24} />
                            )}
                          </IconButton>
                        </Tooltip>
                      )}
                      {actions && (
                        <TutenTableActions
                          active={activeRow}
                          index={rowIndex}
                          actions={actions}
                          row={row}
                        />
                      )}
                      {checkBoxActive && (
                        <Checkbox
                          checked={rowIsChecked.some(
                            rowPro =>
                              rowPro.isChecked &&
                              rowPro.professionalId === row.professionalId,
                          )}
                          disabled={professionals.some(
                            pro => pro.professionalId === row.professionalId,
                          )}
                          onChange={e =>
                            onChangeCheckbox(
                              e,
                              professionals,
                              row,
                              rowIsChecked,
                              setRowIsChecked,
                            )
                          }
                        />
                      )}
                    </TableCell>
                    {columns
                      .filter(({ visible = true }) => visible)
                      .map(({ tag, isLink = false }, index) => (
                        <TableCell
                          key={index}
                          className={`${
                            row[fieldToActive]
                              ? null
                              : getFieldToActive(fieldToActive, classes)
                          }`}
                          style={{
                            color: 'rgba(0, 0, 0, 0.87)',
                          }}
                        >
                          {getIsLink(
                            isLink,
                            editBranchoffice,
                            row,
                            tag,
                            classes,
                            clickData,
                            fieldToActive,
                          )}
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {loadingData && (
            <div className={classes.containerLoading}>
              <CircularProgress className={classes.root} />
              <p>cargando...</p>
            </div>
          )}
        </TableContainer>
        {showPagination && tableData.length > 0 && (
          // NOTA, en la version actual (4.11.4) de Material UI cambiar onChangePage y onChangeRowsPerPage estan deprecados
          // usar onPageChange y onRowsPerPageChange, cuando se actualice la version
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
            labelRowsPerPage={'Filas por página:'}
            count={total}
            page={page}
          />
        )}

        {!loadingData && tableData.length === 0 && (
          <div style={{ textAlign: 'center' }}>
            <img className={classes.noData} src={noData} />
            <Typography className={classes.noDataText}>
              {!recordsLabel && `No hay registros en esta tabla.`}
              {recordsLabel}
            </Typography>
          </div>
        )}
      </Paper>
    </>
  )
}

function onChangeCheckbox(
  e,
  professionals,
  row,
  rowIsChecked,
  setRowIsChecked,
) {
  let includeRow =
    professionals &&
    professionals.some(pro => row.professionalId === pro.professionalId)
  row.isChecked = e.target.checked
  if (row.isChecked && !includeRow) {
    rowIsChecked.push(row)
  } else if (!includeRow) {
    rowIsChecked = rowIsChecked.filter(
      rowPro => rowPro.professionalId !== row.professionalId,
    )
  }
  setRowIsChecked(rowIsChecked)
}

function getIsLink(
  isLink,
  editBranchoffice,
  row,
  tag,
  classes,
  clickData,
  fieldToActive,
) {
  return isLink && editBranchoffice ? (
    <a
      onClick={() => clickData && clickData(row)}
      className={classes.link}
      style={{
        color: fieldToActive && row[fieldToActive] ? '' : 'inherit',
      }}
    >
      {row[tag]}
    </a>
  ) : (
    getItem(tag, row)
  )
}
function getFieldToActive(fieldToActive, classes) {
  return !fieldToActive ? null : classes.inactive
}

function getItem(tag, row) {
  return Array.isArray(tag)
    ? tag
        .map(function (item) {
          return row[item]
        })
        .join(' ')
    : row[tag]
}

TutenTable.propTypes = {
  showPagination: bool,
  actions: func,
  columns: array,
  getData: func,
  filters: any,
  fieldToActive: string,
}

TutenTable.defaultProps = {
  elevation: 1,
}

export { TutenTable }
