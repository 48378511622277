import './App.css'
import { useEffect, useState, useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { routes } from './routes'
import { withRouter } from 'react-router'
import { setSessionData } from './services/checkSessionService'
import { ThemeContext } from './themes/index'

const App = props => {
  const isIframe = props.token ? true : false
  const [thereIsSession, setThereIsSession] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const defaultBaseColor = '#002FC5'
  const defaultContrastTextColor = '#FFF'
  const [primaryColor, setPrimaryColor] = useState(defaultBaseColor)
  const [, setThemeCustom] = useContext(ThemeContext)

  useEffect(() => {
    if (isIframe) {
      if (props.token == window.localStorage.getItem('token')) {
        if (!thereIsSession) {
          redirectIframe()
        }
      } else {
        setIsLoading(true)
        setSessionData(props.token)
          .then(success => {
            if (success) {
              redirectIframe()
            }
          })
          .catch(err => {
            console.error(err)
            window.localStorage.clear()
            props.passDownFunc('NOT_AUTH')
          })
      }
    } else {
      setThereIsSession(true)
    }
  })

  useEffect(() => {
    if (thereIsSession != null) {
      const baseColor = props.base_color
      if (baseColor) {
        setPrimaryColor(baseColor)
        setThemeCustom({
          primary: primaryColor,
          contrastText: defaultContrastTextColor,
          highContrast: !!props.highContrast,
          secondary: primaryColor,
        })
      }
    }
  }, [thereIsSession])

  const redirectIframe = () => {
    setIsLoading(false)
    setThereIsSession(true)
    if (props.iframeUrl) {
      props.history.push(props.iframeUrl)
    }
  }

  return (
    <div className="App">
      <Switch>
        {!isLoading &&
          routes.map(({ path, Component }, index) => {
            return (
              <Route
                exact
                path={path}
                key={index}
                render={() => (
                  <Component
                    props={props}
                    isIframe={isIframe}
                    permissions={props.permissions}
                  />
                )}
              />
            )
          })}
      </Switch>
    </div>
  )
}

export default withRouter(App)
