import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import LimitTagsSelect from '../../../components/atomComponents/selects/limitTagsSelectAutoComplete'
import { useStyles } from '../../services/styles'
import { getCategorysAll } from '../../../services/categories'
import AsyncAutoComplete from '../../../components/atomComponents/selects/asyncAutoComplete'
import { providersByUserShort } from '../../../services/ProviderServices'
import { selectsName } from './constants'

const ServicesForm = props => {
  const classes = useStyles()
  const {
    control,
    watch,
    loadingBu,
    idSucursal,
    setValue,
    setSelectedAll,
    selectedAll,
    handleSelectedProv,
    dataBUList,
    setDataBUList,
  } = props
  const [categoryByBUs, setCategoryByBUs] = useState([])
  const [loadingCategory, setLoadingCategory] = useState(false)
  const [categories, setCategories] = useState(null)
  const [loadingCategories, setLoadingCategories] = useState(false)
  const providerSelected = watch(selectsName.proListCtrl)
  const busSelected = watch(selectsName.buListId)
  const categoriesSelected = watch(selectsName.categoryListId)

  const getAllCategories = async () => {
    setLoadingCategories(true)
    try {
      let { data, status } = await getCategorysAll()
      if (status === 200) {
        setCategories(data)
        setLoadingCategories(false)
      } else {
        setCategories([])
        setLoadingCategories(false)
      }
    } catch {
      setCategories([])
      setLoadingCategories(false)
    }
  }

  const getCategoriesByBUs = async el => {
    setLoadingCategory(true)
    if (categories) {
      let categoriesByBu = categories.filter(item =>
        el.some(e => e.value === item.businessUnitId),
      )
      categoriesByBu = categoriesByBu.map(item => {
        let element = el?.find(i => i.value === item.businessUnitId)
        return {
          label: `${item.name} - ${element.label}`,
          value: item.id,
          categoryName: item.name,
          businessUnitId: item.businessUnitId,
          branchId: idSucursal,
        }
      })
      setCategoryByBUs(categoriesByBu)
      setLoadingCategory(false)
    } else {
      setLoadingCategory(false)
    }
  }

  useEffect(() => {
    if (!categories && !loadingCategories) getAllCategories()
  })

  useEffect(() => {
    if (busSelected) {
      if (categoriesSelected) setValue(selectsName.categoryListId, null)
      getCategoriesByBUs(busSelected)
    } else {
      if (categoriesSelected) setValue(selectsName.categoryListId, null)
    }
  }, [busSelected])

  useEffect(() => {
    if (busSelected && busSelected.length > 0) {
      setValue(selectsName.buListId, null)
    }
    providerSelected ? handleSelectedProv(providerSelected) : setDataBUList([])
  }, [providerSelected])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <AsyncAutoComplete
            name={selectsName.proListCtrl}
            control={control}
            label={'Proveedores'}
            placeholder={'Selecciona'}
            variant={'outlined'}
            required={true}
            helperText={'Selecciona'}
            multiple={true}
            cbEndpoint={providersByUserShort}
            setSelectedAll={setSelectedAll}
            selectedAll={selectedAll}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <LimitTagsSelect
            data={dataBUList}
            id={selectsName.buListId}
            limitTags={2}
            inputLabel={'Unidades de negocio'}
            required={true}
            control={control}
            multiple={true}
            labelSelectAll={'Seleccionar todo'}
            overwriteClasses={{
              option: classes.overwriteOption,
            }}
            OverwriteInputProps={params => ({
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingBu ? (
                    <CircularProgress color="inherit" size={22} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingTop: '15px' }}>
        <Grid item xs={12} sm={6} md={6}>
          <LimitTagsSelect
            data={categoryByBUs}
            id={selectsName.categoryListId}
            limitTags={2}
            inputLabel={'Categorías'}
            required={true}
            control={control}
            multiple={true}
            disabled={!busSelected}
            labelSelectAll={'Seleccionar todo'}
            overwriteClasses={{
              option: classes.overwriteOption,
            }}
            OverwriteInputProps={params => ({
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingCategory ? (
                    <CircularProgress color="inherit" size={22} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            })}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ServicesForm
