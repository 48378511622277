import { api } from './interceptors'
import { buildHeader } from './basicParams'
import { getTenantUUID } from '../utils/sessionUtils'
const API_URL = process.env.REACT_APP_AIR_API_URL

const getIdentificationTypes = async () => {
  const options = buildHeader()
  options.headers.tenantUUID = getTenantUUID()
  try {
    return await api.get(
      `${API_URL}/LoginREST/rest/identification-type/countries-identification-types`,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}

export { getIdentificationTypes }
