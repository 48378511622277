import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { useStyles } from '../../../pages/TemplateUpload/styles'
import { Columns } from '../../../pages/TemplateUpload/constants'

const SimpleTable = ({ icon, fileName, fileSize, fileType, typeUpload }) => {
  const classes = useStyles()
  fileSize =
    fileSize > 1000000
      ? `${Math.floor(fileSize / 1000000)} MB`
      : `${Math.floor(fileSize / 1000)} KB`
  return (
    <Paper elevation={0}>
      <TableContainer className={classes.container}>
        <Table className={classes.tableBody}>
          <TableHead>
            <TableRow>
              {Columns.map(({ text }, index) => (
                <TableCell
                  key={index}
                  className={`${classes.cell} ${classes.keyText}`}
                >
                  {text}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={1} className={classes.row}>
              <TableCell className={`${classes.cell} ${classes.keyText}`}>
                {fileName || ''}
              </TableCell>
              <TableCell className={`${classes.selectUpload}`}>
                {typeUpload || ''}
              </TableCell>
              <TableCell className={`${classes.cell} ${classes.selectUpload}`}>
                {fileType}
              </TableCell>
              <TableCell className={`${classes.cell} ${classes.tag}`}>
                {fileSize}
              </TableCell>
              <TableCell className={`${classes.cell} ${classes.tag}`}>
                {icon}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default SimpleTable
