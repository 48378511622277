import { api } from './interceptors'
import { buildHeader } from './basicParams'
const API_URL = process.env.REACT_APP_AIR_API_URL

const getServicesDefinitions = async () => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/service/definitions`,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}

const getServicesDefinitionsAll = async () => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/service/definitions/all`,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}
const addSucursalService = async (body, idSucursal) => {
  const options = buildHeader()
  try {
    let res = await api.put(
      `${API_URL}/ServiceProviderREST/sucursal-service/${idSucursal}`,
      body,
      options,
    )
    return { data: res, status: 200 }
  } catch (e) {
    console.error()
    return { error: 'error' }
  }
}
const getAllCountries = async () => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/countries`,
      options,
    )
  } catch (e) {
    console.error()
    return { error: 'error' }
  }
}
const getCitiesByCountryId = async countryId => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/get/country/${countryId}/cities`,
      options,
    )
  } catch (e) {
    console.error()
    return { error: 'error' }
  }
}

const getComunesUniqueByCityIdAndBUs = async (cityId, businessUnitList) => {
  let body = businessUnitList.map(bu => bu.businessUnit)
  const options = buildHeader()
  try {
    return await api.post(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/city/${cityId}/search-unique-commune`,
      body,
      options,
    )
  } catch (e) {
    console.error()
    return { error: 'error' }
  }
}

const getServiceDetailByMultiplesBU = async buList => {
  const options = buildHeader()
  let url = `${API_URL}/ServiceDefinitionREST/v2/datail/service/list/by-multiple-business-unit?buList=${buList}`
  try {
    return await api.get(url, options)
  } catch (e) {
    return { error: 'error' }
  }
}

const getCountryByTenant = async tenant => {
  const options = buildHeader()
  let url = `${API_URL}/ServiceDefinitionREST/v2/private/country/${tenant}`
  try {
    return await api.get(url, options)
  } catch (e) {
    return { error: 'error' }
  }
}

const bulkLoadSucursalService = async body => {
  let options = buildHeader()
  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')
  options.headers.firstName = firstName
  options.headers.lastName = lastName

  try {
    return await api.post(
      `${API_URL}/ServiceProviderREST/bulk-load/sucursal-service`,
      body,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const getSucursalNeighborhood = async body => {
  const options = buildHeader()
  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')
  options.headers.firstName = firstName
  options.headers.lastName = lastName
  try {
    return await api.post(
      `${API_URL}/ServiceProviderREST/sucursal-neighborhood`,
      body,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const getAccountBankTypes = async () => {
  const options = buildHeader()
  let url = `${API_URL}/ServiceDefinitionREST/v2/country/accounts/by-tenant`
  try {
    return await api.get(url, options)
  } catch (e) {
    return { error: 'error' }
  }
}

const getBanksNames = async () => {
  const options = buildHeader()
  let url = `${API_URL}/ServiceDefinitionREST/v2/country/banks/by-tenant`
  try {
    return await api.get(url, options)
  } catch (e) {
    return { error: 'error' }
  }
}

export {
  getServicesDefinitions,
  addSucursalService,
  getServicesDefinitionsAll,
  getAllCountries,
  getCitiesByCountryId,
  getComunesUniqueByCityIdAndBUs,
  getServiceDetailByMultiplesBU,
  bulkLoadSucursalService,
  getCountryByTenant,
  getSucursalNeighborhood,
  getAccountBankTypes,
  getBanksNames,
}
