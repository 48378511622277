import React from 'react'
import { Checkbox, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    root: {
      color: theme.palette.action.active || 'rgba(0,0,0, 0.54)',
    },
  }
})

const CustomCheckbox = props => {
  const classes = useStyles()
  return (
    <Checkbox
      classes={{
        root: classes.root,
      }}
      {...props}
    />
  )
}

export default CustomCheckbox
