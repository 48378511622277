// Funcion que recibe un color en hexadecimal y retorna el mismo color en
// pseudo formato rgb '0,0,0'
export const convertHexToRGB = hexColor => {
  let c
  try {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexColor)) {
      c = hexColor.substring(1).split('')
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = '0x' + c.join('')
      c = [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')
      return c
    } else return '0, 174, 199'
  } catch (e) {
    return '0, 174, 199'
  }
}
