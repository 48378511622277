import { api } from './interceptors'
import { buildHeader } from './basicParams'
const API_URL = process.env.REACT_APP_AIR_API_URL

const getCategorysAll = async () => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/obtener/categorys/all`,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}

export { getCategorysAll }
