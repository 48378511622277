let ops = { year: 'numeric', month: '2-digit', day: '2-digit' }
let templates = [
  {
    id: 1,
    creationDate: 1646794304715,
    templateName: 'Plantilla de profesionales',
    url: 'templates/PlantillaProfesionales.xlsx',
  },
  {
    id: 2,
    creationDate: 1646794304715,
    templateName: 'Plantilla de proveedores',
    url: 'templates/PlantillaProveedores.xlsx',
  },
  {
    id: 3,
    creationDate: 1646794304715,
    templateName: 'Plantilla de conceptos de costos',
    url: 'templates/PlantillaConceptosDeCostos.xlsx',
  },
  {
    id: 4,
    creationDate: 1646794304715,
    templateName: 'Asociación masiva de servicios',
    url: 'templates/PlantillaDefinicionServiciosSucursales.xlsx',
  },
  {
    id: 5,
    creationDate: 1646794304715,
    templateName: 'Asociación masiva de zonas',
    url: 'templates/PlantillaZonasSucursales.xlsx',
  },
  {
    id: 6,
    creationDate: 1646794304715,
    templateName: 'Asociación masiva de concepto de costo',
    url: 'templates/PlantillaZonasSucursales.xlsx',
  },
]
const getTemplates = async () => {
  const transformDate = date => {
    return new Date(date).toLocaleDateString(undefined, ops)
  }
  try {
    templates = templates.map(template => ({
      ...template,
      creationDate: transformDate(template.creationDate),
    }))
    return { items: templates, total: templates.length, status: 200 }
  } catch (e) {
    console.error(e)
  }
}

export { getTemplates }
