export const GOOGLE_CAPTCHA_API_KEY = "6LduOAoaAAAAAJ-Aa84QpXxKf8oCEdv6XOE9M5-2"

// Event tags names to filters
export const filtersTagEvents = {
    professionalTemplateId: 1,
    providerTemplateId: 2,
    servicesTemplateId: 4,
    zonesTemplateId: 5,
    costConceptTemplateId: 6,
}

export const sessionKeys = {
    backofficeName: 'backofficeName',
    baseColor: 'baseColor',
    businessUnits: 'businessUnits',
    dateFormatCode: 'dateFormatCode',
    email: 'email',
    firstName: 'firstName',
    highContrast: 'highContrast',
    languageURL: 'languageURL',
    lastConnection: 'lastConnection',
    lastName: 'lastName',
    logoBO: 'logoBO',
    permissions: 'permissions',
    refreshBefore: 'refreshBefore',
    refreshToken: 'refreshToken',
    scopeId: 'scopeId',
    tenantName: 'tenantName',
    tenantUUID: 'tenantUUID',
    timeFormatCode: 'timeFormatCode',
    timezoneIdName: 'timezoneIdName',
    token: 'token',
    userId: 'userId',
    username: 'username',
    providerId: 'providerId',
    languageFile: 'languageFile',
  }