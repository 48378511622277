/* eslint-disable no-undef */
import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { hist } from '../utils/history'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import languageProviderReducer from '../utils/LanguageProvider/reducer'

const rootReducer = combineReducers({
  language: languageProviderReducer,
  router: connectRouter(hist),
})

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware, routerMiddleware(hist)]
const enhancers = [applyMiddleware(...middlewares)]

let composeEnhancers = compose
if (process.env.NODE_ENV !== 'production' && typeof window === 'object')
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})

const store = createStore(rootReducer, composeEnhancers(...enhancers))

// sagaMiddleware.run(rootSaga)

export default store
