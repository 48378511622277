const app = 'APP_BCK'

export const buildHeader = () => {
  return {
    headers: {
      app,
      token: window.localStorage.getItem('token'),
      userId: parseInt(window.localStorage.getItem('userId')),
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
  }
}

export const buildWithOutHeader = () => {
  return {
    headers: {
      jwt: window.localStorage.getItem('token'),
      userId: parseInt(window.localStorage.getItem('userId')),
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
  }
}

export const buildHeaderWithParams = (token, userId) => {
  return {
    headers: {
      app,
      token,
      userId,
    },
  }
}
