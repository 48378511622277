import axios from 'axios'
export const api = axios.create()
// Request interceptor
api.interceptors.request.use(
  async config => {
    return config
  },
  error => {
    Promise.reject(error)
  },
)

// Response interceptor
api.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (
      error.response &&
      (error.response.data.key === 'INVALID_TOKEN' ||
        error.response.data.key === 'JWT_EXPIRED')
    ) {
      localStorage.clear()
      window.location.href = '/test'
    }
    return error?.response
  },
)
