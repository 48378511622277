import { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import LimitTagsSelect from '../../../components/atomComponents/selects/limitTagsSelectAutoComplete'
import {
  getCitiesByCountryId,
  getAllCountries,
} from '../../../services/servicesDefinitions'
import { useStyles } from '../../services/styles'
import AsyncAutoComplete from '../../../components/atomComponents/selects/asyncAutoComplete'
import { providersByUserShort } from '../../../services/ProviderServices'
import { selectsName } from './constants'

const ZonesForm = props => {
  const classes = useStyles()
  const {
    control,
    watch,
    regions,
    setRegions,
    handleSelectedZone,
    handleSelectedProvider,
    setSelectedAll,
    selectedAll,
  } = props
  const [loadingRegions, setLoadingRegions] = useState(false)
  const zoneSelected = watch(selectsName.regionListCtrl)
  const providerSelected = watch(selectsName.proListCtrl)
  useEffect(() => {
    if (!loadingRegions) {
      fetchCountryRegion()
    }
  }, [])

  useEffect(() => {
    zoneSelected && handleSelectedZone(zoneSelected)
  }, [zoneSelected])

  useEffect(() => {
    providerSelected && handleSelectedProvider(providerSelected)
  }, [providerSelected])

  const fetchCountryRegion = async () => {
    setLoadingRegions(true)
    let regions = []
    let countries = await generateCountries()
    countries?.map(c => {
      c.cities?.map(r => {
        regions.push({
          value: r.id,
          label: r.name,
          country: c.id,
          countryName: c.name,
        })
      })
    })
    setRegions(regions)
    setLoadingRegions(false)
  }
  const generateCountries = async () => {
    let res = await getAllCountries()
    if (res.status === 200) {
      return Promise.all(
        res.data.items?.map(async c => {
          return {
            id: c.id,
            name: c.name,
            cities: await generateCitiesCountry(c),
          }
        }),
      )
    } else {
      setLoadingRegions(false)
    }
  }
  const generateCitiesCountry = async country => {
    let res = await getCitiesByCountryId(country.id)
    if (res.status === 200) {
      return Promise.all(
        res.data.items?.map(async c => {
          return {
            id: c.id,
            name: c.name + ' - ' + country.name,
            countryId: country.id,
          }
        }),
      )
    } else {
      setLoadingRegions(false)
    }
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <LimitTagsSelect
            data={regions}
            id={selectsName.regionListCtrl}
            limitTags={2}
            inputLabel={'Region'}
            required={true}
            control={control}
            multiple={true}
            labelSelectAll={'Seleccionar todo'}
            overwriteClasses={{
              option: classes.overwriteOption,
            }}
            OverwriteInputProps={params => ({
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingRegions ? (
                    <CircularProgress color="inherit" size={22} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            })}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <AsyncAutoComplete
            name={selectsName.proListCtrl}
            control={control}
            label={'Proveedores'}
            placeholder={'Seleccione los proveedores'}
            variant={'outlined'}
            required={true}
            helperText={'Seleccione los proveedores'}
            multiple={true}
            cbEndpoint={providersByUserShort}
            setSelectedAll={setSelectedAll}
            selectedAll={selectedAll}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ZonesForm
