import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '80px !important',
    '& > * + *': {
      marginTop: theme.spacing(29),
    },
  },
  overwriteOption: {
    height: '32px',
  },
}))
