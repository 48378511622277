import { api } from './interceptors'
import { buildHeader } from './basicParams'
import { getAccountBankTypes, getBanksNames } from './servicesDefinitions'

const API_URL = process.env.REACT_APP_AIR_API_URL

export const businessUnits = () => {
  let arrBUs = JSON.parse(localStorage['businessUnits'] || '[]')
  return arrBUs.map(({ id: value, name: label }) => ({
    value,
    label,
  }))
}

const professionalCharge = async (file, bu) => {
  const options = buildHeader()
  options.headers.firstName = localStorage['firstName']
  options.headers.lastName = localStorage['lastName']
  var formData = new FormData()
  formData.append('file', file)
  try {
    return await api.post(
      `${API_URL}/ServiceProviderREST/bulk-load/professional/charge/${bu}`,
      formData,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}

const professionalChargeV2 = async (body, fileName) => {
  const options = buildHeader()
  options.headers.firstName = localStorage['firstName']
  options.headers.lastName = localStorage['lastName']
  options.headers.fileName = fileName
  try {
    return await api.post(
      `${API_URL}/ServiceProviderREST/bulk-load/professional/charge/v2`,
      body,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const generateAccountBankTypesByBuList = async () => {
  let account = []
  const res = await getAccountBankTypes()
  const dataFilter = res.data.data.filter(item => item.accounts.length > 0)
  if (dataFilter != undefined) {
    dataFilter.forEach(item => {
      account.push(...item.accounts)
    })
    let resultList = account.filter((item, index) => {
      return account.indexOf(item) === index
    })
    return resultList
  }
  return []
}

const generateBanksNamesByBuList = async () => {
  let banks = []
  const res = await getBanksNames()
  const dataFilter = res.data.data.filter(item => item.banks.length > 0)
  if (dataFilter != undefined) {
    dataFilter.forEach(item => {
      banks.push(...item.banks)
    })
    let resultList = banks.filter((item, index) => {
      return banks.indexOf(item) === index
    })
    return resultList
  }
  return []
}

export {
  professionalCharge,
  professionalChargeV2,
  generateAccountBankTypesByBuList,
  generateBanksNamesByBuList,
}
