import { makeStyles } from '@material-ui/core/styles'
import { convertHexToRGB } from '../../util/util'

export const useStyles = makeStyles(theme => {
  let c = convertHexToRGB(theme.palette.primary.main)
  return {
    tableRow: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: `rgba(${c},0.08) !important`,
        '& a': {
          color: '#002FC5 !important',
          textDecoration: 'underline',
        },
      },
      '&.MuiTableRow-hover': {
        '&.MuiTableRow-hover:hover': {
          '& a': {
            color: '#002FC5 !important',
            textDecoration: 'underline',
          },
        },
        a: {
          color: 'red !important',
        },
      },
    },
    tableCell: {
      '$hover:hover &': {
        color: 'pink',
      },
    },
    table: {
      fontFamily: 'Roboto',
      minWidth: 650,
    },
    row: {
      '& .actions': {
        visibility: 'hidden',
      },
      '&:hover .actions': {
        visibility: 'visible !important',
      },
    },
    rowHover: {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
    cell: {
      padding: '2px 24px 2px 16px !important',
    },
    sortable: {
      cursor: 'pointer',
    },
    icon: {
      height: 14,
      transition: 'transform .5s ease-in-out',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    noData: {
      marginTop: 43,
    },
    noDataText: {
      fontSize: 20,
      fontWeight: 500,
      paddingBottom: 35,
    },
    inactive: {
      color: 'rgba(0, 0, 0, 0.38) !important',
    },
    link: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        color: '#002FC5 !important',
        textDecoration: 'underline',
        cursor: 'pointer',
        fontWeight: 500,
        fontSize: '14px',
      },
    },
    text: {
      fontWeight: '700 !important',
    },
    containerLoading: {
      width: '100%',
      minHeight: '260px',
      fontSize: '14px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    hover: {
      '&:hover': {
        backgroundColor: `rgba(${c},0.08) !important`,
      },
    },
    disabledButton: {
      color: 'rgba(0, 0, 0, 0.26)',
    },
    enabledButton: {
      color: 'primary',
    },
    iconContainer: {
      '&:hover': {
        color: `rgba(${c}) !important`,
      },
      '&:active': {
        color: `rgba(${c}) !important`,
      },
    },
  }
})
