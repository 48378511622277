import { api } from './interceptors'
import { buildHeader } from './basicParams'

const API_URL = process.env.REACT_APP_AIR_API_URL

const costConceptsCharge = async (file, bu) => {
  const options = buildHeader()
  options.headers.firstName = localStorage['firstName']
  options.headers.lastName = localStorage['lastName']
  var formData = new FormData()
  formData.append('file', file)
  try {
    return await api.post(
      `${API_URL}/PricingREST/cost/concept/file/bulk-load?businessUnitUUID=${bu}`,
      formData,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}

const bulkLoadCostConceptAssociation = async body => {
  let options = buildHeader()
  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')
  options.headers.firstName = firstName
  options.headers.lastName = lastName

  try {
    return await api.post(
      `${API_URL}/PricingREST/relationships/concept/generate/cc-files`,
      body,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const uploadCCrBulkLoad = async file => {
  const options = buildHeader()
  options.headers.firstName = localStorage.getItem('firstName')
  options.headers.lastName = localStorage.getItem('lastName')
  var formData = new FormData()
  formData.append('file', file)
  try {
    return await api.post(
      `${API_URL}/PricingREST/relationships/concept/file/bulk-load/`,
      formData,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

export { costConceptsCharge, bulkLoadCostConceptAssociation, uploadCCrBulkLoad }
