/**
 * Este es un select con auto completado
 * en el cual se pueden limitar los tags(chips) mostrados
 * por fila, cuando este es multiple
 */
import React from 'react'
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { makeStyles, Checkbox, TextField } from '@material-ui/core'
import {
  inputPropsDefault,
  useStylesInputLabel,
} from '../../../util/inputPropsDefault'
import { string, array, number, bool, func, object } from 'prop-types'
import { Controller } from 'react-hook-form'

const useStyles = makeStyles(theme => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  asterikColor: {
    color: '#f44336',
  },
  indeterminate: {
    color: theme.palette.primary.main,
    '&.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
  option: {
    height: '32px',
  },
  inputRoot: {
    '&.MuiOutlinedInput-root': {
      padding: '7.5px',
    },
  },
  notInputRoot: {},
  overWriteChip: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 80,
    margin: '0',
    boxSizing: 'content-box',
  },
  rootCheckbox: {
    color: theme.palette.action.active || 'rgba(0,0,0, 0.54)',
  },
}))
const generateOverwriteInputProps = OverwriteInputProps => {
  if (OverwriteInputProps) return OverwriteInputProps
  else return params => params.InputProps
}
const limitTagsSelectAutoComplete = ({
  data,
  id,
  limitTags,
  inputPlaceholder,
  inputLabel,
  required,
  control,
  multiple,
  disabled,
  labelSelectAll,
  overwriteClasses,
  OverwriteInputProps,
}) => {
  const classes = useStyles()
  const classesInput = useStylesInputLabel()
  const filter = createFilterOptions()
  const overwriteInputProps = generateOverwriteInputProps(OverwriteInputProps)

  const handleChange = (selectedOptions, reason, onChange, value) => {
    const allSelected = value?.length === data?.length
    if (reason === 'clear') {
      onChange(selectedOptions)
    } else {
      if (selectedOptions.find(option => option.value === 'select-all')) {
        if (allSelected) {
          return onChange([])
        }
        return onChange(data)
      } else {
        return onChange(selectedOptions)
      }
    }
  }

  const getOptionSelected = (option, anotherOption) =>
    option.value === anotherOption.value
  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
        const allSelected = value?.length === data?.length
        return (
          <Autocomplete
            multiple={multiple}
            limitTags={limitTags}
            id={id}
            value={value || []}
            disabled={disabled}
            options={data}
            getOptionLabel={option => option.label}
            onChange={(...args) => {
              handleChange(args[1], args[2], onChange, value)
            }}
            disableCloseOnSelect
            getOptionSelected={getOptionSelected}
            classes={{
              ...overwriteClasses,
              inputRoot:
                value?.length > 0 ? classes.inputRoot : classes.notInputRoot,
            }}
            onInputChange={(...args) => {
              // solo cambia cuando no hay valor
              if (!multiple && !args[1]) {
                onChange(args[1])
              }
            }}
            ChipProps={{
              classes: {
                label: classes.overWriteChip,
              },
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params)
              return data.length > 1
                ? [{ label: labelSelectAll, value: 'select-all' }, ...filtered]
                : [...filtered]
            }}
            getLimitTagsText={num => <>{`(+${num})`}</>}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox
                  style={{ marginLeft: option.value === 'select-all' ? -8 : 8 }}
                  indeterminate={
                    option.value === 'select-all' &&
                    value?.length > 0 &&
                    !allSelected
                  }
                  checked={
                    option.value === 'select-all' ? allSelected : selected
                  }
                  classes={{
                    root: classes.rootCheckbox,
                    indeterminate: classes.indeterminate,
                  }}
                />
                {option.label}
              </>
            )}
            renderInput={params => {
              params = {
                disabled: params.disabled,
                fullWidth: params.fullWidth,
                id: params.id,
                InputProps: params.InputProps,
                inputProps: params.inputProps,
                InputLabelProps: params.InputLabelProps,
                size: params.size,
              }
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  required={required}
                  label={inputLabel}
                  error={!!error}
                  inputRef={ref}
                  placeholder={inputPlaceholder || 'Selecciona'}
                  InputLabelProps={{
                    ...inputPropsDefault(classesInput),
                  }}
                  InputProps={{
                    ...params.InputProps,
                    ...overwriteInputProps(params),
                  }}
                />
              )
            }}
          />
        )
      }}
      rules={{
        required: required,
      }}
    />
  )
}

limitTagsSelectAutoComplete.propTypes = {
  data: array, // array with object options
  id: string, // id to identify element
  limitTags: number, // quantity of tags to show
  inputPlaceholder: string, // placeholder to input element
  inputLabel: string, // label to input
  required: bool,
  control: object, // object control react-hook-form
  multiple: bool,
  disabled: bool,
  labelSelectAll: string, // label to select all option
  overwriteClasses: object, // object to overwrite classes
  OverwriteInputProps: func, // function to overwrite input props(should return an object)
}

limitTagsSelectAutoComplete.defaultProps = {
  data: [],
  limitTags: 2,
  inputPlaceholder: 'Selecciona',
  required: false,
  multiple: false,
  disabled: false,
  overwriteClasses: {},
}

export default limitTagsSelectAutoComplete
