import { api } from './interceptors'
import { buildHeader } from './basicParams'
const API_URL = process.env.REACT_APP_AIR_API_URL

const getAllCountries = async () => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/countries`,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const getAllCitys = async () => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/cities/all`,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const getAllComuneByCitys = async body => {
  const options = buildHeader()
  try {
    return await api.post(
      `${API_URL}/ServiceDefinitionREST/rest/servicedefinition/commune-by-ids-cities`,
      body,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const getIdentificationTypesByTennant = async () => {
  const options = buildHeader()
  try {
    return await api.get(
      `${API_URL}/LoginREST/rest/identification-type`,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const providersCharge = async (body, fileName) => {
  const options = buildHeader()
  options.headers.firstName = localStorage['firstName']
  options.headers.lastName = localStorage['lastName']
  options.headers.fileName = fileName
  try {
    return await api.post(
      `${API_URL}/ServiceProviderREST/provider/charge/v2`,
      body,
      options,
    )
  } catch (e) {
    return { error: 'error' }
  }
}

const providersByUserShort = async (filtro, limit) => {
  const queryFiltro = !filtro ? '' : `&filtro=${filtro}`
  const queryLimit = !limit ? '' : `&limit=${limit}`
  const options = buildHeader()
  try {
    let res = await api.get(
      `${API_URL}/ServiceProviderREST/provider/list-providers-by-bu-user-short?onlyActive=true&buList=true${queryFiltro}${queryLimit}`,
      options,
    )
    if (res.status === 200) {
      res.data = res.data.map(obj => {
        return {
          value: obj.id,
          label: obj.nombreEmpresa || obj.razonSocial,
          sucursales: JSON.parse(obj.sucursales || '[]') || [],
          country: obj.country,
          countryName: obj.countryName,
          listBusinessUnit: obj.businessUnits
            ? obj.businessUnits.split(',')
            : [],
          nameProvider: obj.nombreEmpresa || obj.razonSocial,
          providerId: obj.id,
        }
      })
      return res
    } else return res
  } catch (e) {
    return { error: 'error' }
  }
}

export {
  getAllCountries,
  getIdentificationTypesByTennant,
  providersCharge,
  providersByUserShort,
  getAllCitys,
  getAllComuneByCitys,
}
