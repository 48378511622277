import {
  bulkLoadSucursalService,
  getSucursalNeighborhood,
} from '../../services/servicesDefinitions'

import { bulkLoadCostConceptAssociation } from '../../services/costConceptsService'
import { getTenantUUID } from '../../utils/sessionUtils'
export const bulkLoadOptions = [
  {
    value: 'bulkLoadServices',
    label: 'Asociación Masiva Servicios',
  },
]

const handlerResponse = (res, controlResponse) => {
  if (res.status === 200) {
    controlResponse.setBulkLoadModalOpen(false)
    controlResponse.onSuccess(
      'Generación exitosa',
      'El documento ha sido generado con éxito, dentro de los próximos minutos recibirás un correo con la información solicitada',
    )
  } else {
    controlResponse.onError(
      'Error',
      'Ha ocurrido un error, inténtalo más tarde',
    )
  }
}

export const bulkLoadGenerateDocument = async (
  dataForm,
  branchId,
  zoneForm,
  titleBulkLoad,
  buList,
  controlResponse,
  selectedAll,
) => {
  let body
  let branchList = branchId
  if (titleBulkLoad == 'Zonas') {
    let listBu = buList.map(e => e.value)
    const tennatId = getTenantUUID()
    body = {
      bussinessUnit: listBu,
      cities: zoneForm.cities,
      providers: selectedAll ? [] : zoneForm.providers,
      different: true,
      tenantUUID: selectedAll ? tennatId : null,
    }
    let res = await getSucursalNeighborhood(body)
    handlerResponse(res, controlResponse)
  } else {
    let categoryIdList = []
    if (dataForm.categoryListId) {
      categoryIdList = dataForm.categoryListId.map(c => {
        return `${c.value}`
      })
    }

    let buIdList = dataForm.buListId.map(b => {
      return b.value
    })
    const tennatId = getTenantUUID()
    body = {
      bussinessUnit: buIdList,
      categories: categoryIdList,
      sucursalId: branchList,
      different: true,
      tenantUUID: selectedAll ? tennatId : null,
    }
    if (titleBulkLoad == 'concepto de costo') {
      body.tenantUUID = null
      let res = await bulkLoadCostConceptAssociation(body)
      handlerResponse(res, controlResponse)
    } else {
      let res = await bulkLoadSucursalService(body)
      handlerResponse(res, controlResponse)
    }
  }
}
