import React, { useState, useRef, useEffect } from 'react'
import {
  styled,
  DialogContentText,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
} from '@material-ui/core'
import BootstrapDialogTitle from '../dialogComponent/BootstrapDialogTitle'
import { MaterialButton } from '../../atomComponents/Buttons'

const BootstrapDialog = styled(Dialog)(({ theme, st }) => {
  return {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
    },
    '& .MuiDialog-paperWidthSm': {
      width: `${st.width} !important`,
      maxWidth: 'none !important',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(0),
    },
    '& .MuiBackdrop-root': {
      backgroundColor: '#80808066',
    },
  }
})

const DialogComponent = ({
  open,
  setOpen,
  title,
  msg,
  buttons,
  modalSize,
  disabledTitle,
}) => {
  const [scroll] = useState('paper')
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return false
    } else {
      setOpen(false)
    }
  }
  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        st={{ width: modalSize || 'fit-content' }}
      >
        {!disabledTitle && (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            handleClose={handleClose}
          >
            <Typography
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                fontWeight: '600',
                fonSize: '20px',
              }}
            >
              {title}
            </Typography>
          </BootstrapDialogTitle>
        )}
        <DialogContent
          dividers={scroll === 'paper'}
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            width: '100%',
          }}
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component="div"
            style={{
              color: 'rgba(0, 0, 0, 0.87)',
              width: '100%',
              margin: 'auto',
              maxWidth: '100% !important',
            }}
          >
            <Typography component="" style={{ width: 'auto' }}>
              {msg}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingRight: '8px',
          }}
        >
          {buttons?.map((btn, i) => (
            <MaterialButton
              text={btn.text}
              onClick={() => btn.action()}
              key={i}
              variant={btn.variant}
              disabled={btn.disabled || false}
            />
          ))}
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default DialogComponent
