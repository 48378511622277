import { defineMessages } from 'react-intl'

export const scope = 'app.pages.MassiveUploadsMaintainer'

export default defineMessages({
  availableTemplates: {
    id: `${scope}.availableTemplates`,
    defaultMessage: 'PLANTILLAS DISPONIBLES',
  },
  templateUpload: {
    id: `${scope}.templateUpload`,
    defaultMessage: 'CARGA DE PLANTILLA',
  },
})
