import { api } from './interceptors'
import { buildHeader } from './basicParams'

const API_URL = process.env.REACT_APP_AIR_API_URL

export const businessUnits = () => {
  let arrBUs = JSON.parse(localStorage['businessUnits'] || '[]')
  return arrBUs.map(({ id: value, name: label }) => ({
    value,
    label,
  }))
}

const branchOfficesSrvCharge = async file => {
  const options = buildHeader()
  options.headers.firstName = localStorage['firstName']
  options.headers.lastName = localStorage['lastName']
  var formData = new FormData()
  formData.append('file', file)
  try {
    return await api.post(
      `${API_URL}/ServiceProviderREST/bulk-load/sucursal-service/charge/`,
      formData,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}

const branchOfficesNeighborhoodCharge = async file => {
  const options = buildHeader()
  options.headers.firstName = localStorage['firstName']
  options.headers.lastName = localStorage['lastName']
  var formData = new FormData()
  formData.append('file', file)
  try {
    return await api.post(
      `${API_URL}/ServiceProviderREST/sucursal-neighborhood/charge`,
      formData,
      options,
    )
  } catch (e) {
    console.error(e)
  }
}

export { branchOfficesSrvCharge, branchOfficesNeighborhoodCharge }
