import { Box, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'
import messages from './messages'
import { FormattedMessage } from 'react-intl'
import TemplatesMaintainer from './TemplatesMaintainer/TemplatesMaintainer.jsx'
import TemplateUpload from './TemplateUpload/TemplateUpload.jsx'
import { useStyles } from './styles'

const MassiveUploadsMaintainer = props => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  function handleIndex(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  return (
    <>
      <Box sx={{ borderBottom: 5, borderColor: 'divider' }}>
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: 'primary',
            },
          }}
          sx={{ width: '100%' }}
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={<FormattedMessage {...messages.availableTemplates} />}
            {...handleIndex(0)}
            style={{ minWidth: '50%' }}
          />
          <Tab
            label={<FormattedMessage {...messages.templateUpload} />}
            {...handleIndex(1)}
            style={{ minWidth: '50%' }}
          />
        </Tabs>
      </Box>
      <div className={classes.tabsContainer}>
        {value === 0 ? (
          <TemplatesMaintainer />
        ) : (
          <TemplateUpload permissions={props.permissions} />
        )}
      </div>
    </>
  )
}

export default MassiveUploadsMaintainer
