import { defineMessages } from 'react-intl'

export const scope = 'app.pages.MassiveUploadsMaintainer.TemplateUpload'
const generalScope = 'app.general'

export default defineMessages({
  error: {
    id: `${generalScope}.error`,
    defaultMessage: 'Error',
  },
  fileFormatWrong: {
    id: `${generalScope}.fileFormatWrong`,
    defaultMessage:
      'El tipo de archivo es incorrecto, debe incluir un archivo .xlsx.',
  },
  loadError: {
    id: `${generalScope}.loadError`,
    defaultMessage: 'Error en la carga',
  },
  loadSuccessful: {
    id: `${generalScope}.loadSuccessful`,
    defaultMessage: 'Carga exitosa',
  },
  errorSendFile: {
    id: `${generalScope}.errorSendFile`,
    defaultMessage:
      'El título de las columnas es incorrecto. Por favor, verifica y vuelve a intentar.',
  },
  successfulFileUpload: {
    id: `${generalScope}.successfulFileUpload`,
    defaultMessage: 'El archivo ha sido cargado con éxito.',
  },
  errorGetData: {
    id: `${scope}.errorGetData`,
    defaultMessage: 'Error al obtener datos',
  },
  creationDate: {
    id: `${scope}.creationDate`,
    defaultMessage: 'Fecha de creación',
  },
  dropTitle: {
    id: `${scope}.dropTitle`,
    defaultMessage: 'Carga de archivos',
  },
  dropTextFirst: {
    id: `${scope}.dropTextFirst`,
    defaultMessage: 'Arrastra tu archivo acá',
  },
  dropTextTwo: {
    id: `${scope}.dropTextTwo`,
    defaultMessage: 'o selecciónalo desde tu computador haciendo click aquí.',
  },
  dropInfo: {
    id: `${scope}.dropInfo`,
    defaultMessage: 'Formato permitido: XLSX',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Nombre',
  },
  typeOfLoad: {
    id: `${scope}.typeOfLoad`,
    defaultMessage: 'Tipo de carga',
  },
  businessUnit: {
    id: `${scope}.businessUnit`,
    defaultMessage: 'Unidad de negocio',
  },
  size: {
    id: `${scope}.size`,
    defaultMessage: 'Tamaño',
  },
  sendFile: {
    id: `${scope}.sendFile`,
    defaultMessage: 'Enviar Archivo',
  },
  helperTypeUpload: {
    id: `${scope}.helperTypeUpload`,
    defaultMessage: 'Tipo de carga requerido',
  },
  helperBusinessUnit: {
    id: `${scope}.helperBusinessUnit`,
    defaultMessage: 'Unidad de negocio requerido',
  },
  couldNotSend: {
    id: `${generalScope}.couldNotSend`,
    defaultMessage:
      'No se pudo enviar el archivo. Por favor, verifica y vuelve a intentar.',
  },
  errorToGenerateData: {
    id: `${scope}.errorToGenerateData`,
    defaultMessage: 'Error al intentar generar datos',
  },
  errorUploadNullsTitle: {
    id: `${scope}.errorUploadNullsTitle`,
    defaultMessage: 'Error al cargar',
  },
  errorUploadNulls: {
    id: `${scope}.errorUploadNulls`,
    defaultMessage:
      'Verifica los campos requeridos y vuelve a intentarlo. Recibirás un correo con mas detalles.',
  },
  errorInHeadersTitle: {
    id: `${scope}.errorInHeadersTitle`,
    defaultMessage: 'Error en títulos',
  },
  errorInHeaders: {
    id: `${scope}.errorInHeaders`,
    defaultMessage: 'Verifica el título de las columnas y vuelve a intentarlo.',
  },
  errorGeneric: {
    id: `${scope}.errorGeneric`,
    defaultMessage: 'Ha ocurrido un error, inténtalo más tarde.',
  },
})
